var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column q-detail-survey-item",class:_vm.className,on:{"click":_vm.onClick}},[_c('survey-label',{attrs:{"bold":true,"type":_vm.questionType.type,"icon":_vm.questionType.icon,"label":this.label || this.questionType.name || this.questionType.type}}),(_vm.questionType.type === _vm.QUESTION_TYPES.CHECKBOX)?[_vm._m(0)]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.SELECT || _vm.questionType.type === _vm.QUESTION_TYPES.ACTION)?[_vm._m(1)]:_vm._e(),(
        [_vm.QUESTION_TYPES.BOOL, _vm.QUESTION_TYPES.RADIO].includes(
          _vm.questionType.type
        )
      )?[_c('div',{staticClass:"pt-1"},[_c('input',{attrs:{"type":"radio","disabled":""}}),_c('label',{staticClass:"mx-1 my-0",staticStyle:{"color":"#aaa"},attrs:{"for":'radio_true'}},[_vm._v("si")]),_c('input',{attrs:{"type":"radio","disabled":""}}),_c('label',{staticClass:"mx-1 my-0",staticStyle:{"color":"#aaa"},attrs:{"for":'radio_false'}},[_vm._v("no")])])]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.DATE)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"2021-08-25","disabled":"","type":"text"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.NUMBER)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"128","disabled":"","type":"number"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.DECIMAL)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"1.5","disabled":"","type":"number"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.LONGTEXT)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"ok","disabled":"","type":"text"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.STRING)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"ok","disabled":"","type":"text"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.MONEY)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"12.8€","disabled":"","type":"text"}})]:_vm._e(),(_vm.questionType.type === _vm.QUESTION_TYPES.PHOTO)?[_c('input',{staticClass:"question-input-sample",attrs:{"placeholder":"upload photo","disabled":"","type":"text"}})]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('input',{staticStyle:{"margin-top":"3px"},attrs:{"disabled":"","type":"checkbox","checked":""}}),_c('small',{staticStyle:{"margin-top":"2px","margin-left":"2px","color":"#666"}},[_vm._v("opcion")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('select',{staticClass:"question-input-sample",attrs:{"disabled":""}},[_c('option',{attrs:{"selected":""}},[_vm._v("opcion")])])
}]

export { render, staticRenderFns }
<template>
  <fieldset class="m-0 p-2" v-if="component && component.component_options">
    <legend>
      <b-badge class="legend-title">Opciones Componente</b-badge>
    </legend>

    <div class="d-flex">
      <span class="h6 mr-1">Tipo:</span>
      <span class="h6">Select</span>
    </div>

    <div>
      <label class="mr-1">Multiples:</label>
      <input
        class=""
        type="checkbox"
        v-model="component.isMultipleCompOptions"
        @change="onChangeIsMultiple"
      />
    </div>

    <div>
      <div class="d-flex align-center my-2">
        <v-text-field
          v-model="optVal"
          label="Añadir Opcion"
          variant="outlined"
          dense
          hide-details="auto"
        ></v-text-field>

        <b-button
          @click="addOpt(optVal)"
          size="sm"
          class="btn-small mr-1 no-radius"
          variant="outline-secondary"
        >
          <b-icon icon="plus-circle"></b-icon>
        </b-button>
      </div>

      <div class="d-flex flex-column">
        <div
          class="py-2"
          :key="`component_opt_values_${key}`"
          v-for="(val, key) in component.component_options.values"
        >
          <div
            class="d-flex justify-content-between border px-1 py-1"
            style="color: blueviolet"
          >
            {{ formatOptionStr(val) }}

            <input
              type="button"
              class="button-sm "
              value="X"
              @click="removeOpt(key)"
            />
          </div>
          <imageUploader
            class="mt-2"
            :imageCategory="`COMPONENT_OPTION`"
            :data="val"
            @onChangePhotoId="
              (photoId, imagePreviewUrl) =>
                onChangePhotoId(photoId, imagePreviewUrl, key)
            "
            @removePhotoId="removePhotoId(key)"
          />
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { ellipsis, logError } from "@/utils";
import _ from "lodash";
import imageUploader from "./imageUploader";

export default {
  name: "comOptionsBuilder",

  props: ["value", "component"],

  components: {
    imageUploader,
  },

  data() {
    return {
      optVal: null,
      templateOptItem: {
        optionLabel: null,
        optionValue: null,
        photoId: null,
        surveyComponentOptionPhoto: null,
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    onChangePhotoId(photoId, imagePreviewUrl, key) {
      this.component.component_options.values[key].photoId = photoId;
      this.component.component_options.values[
        key
      ].imagePreviewUrl = imagePreviewUrl;
    },
    removePhotoId(key) {
      this.component.component_options.values[key].photoId = null;
      this.component.component_options.values[key].imagePreviewUrl = null;
      this.component.component_options.values[
        key
      ].surveyComponentOptionPhoto = null;
    },
    ellipsis,
    formatOptionStr(optItem) {
      return `${ellipsis(optItem.optionLabel, 100)}`;
    },
    removeOpt(removeKey) {
      this.component.component_options.values = _.omit(
        this.component.component_options.values,
        [removeKey]
      );
    },
    addOpt(val) {
      if (val === null) {
        logError("valores nulos no aceptados");
        return;
      }
      let keyList = Object.keys(this.component.component_options.values);
      let insertKey = 1;
      if (keyList.length > 0) {
        let finalKey = keyList[keyList.length - 1];
        insertKey = parseInt(finalKey) + 1;
      }
      if (
        !Object.values(this.component.component_options.values).includes(val)
      ) {
        let newPushItem = JSON.parse(JSON.stringify(this.templateOptItem));
        newPushItem.optionValue = insertKey;
        newPushItem.optionLabel = val;
        this.component.component_options.values[
          `${newPushItem.optionValue}`
        ] = newPushItem;
        this.optVal = "";
      }
    },
    onChangeIsMultiple() {
      this.component.component_options.multi = this.component.isMultipleCompOptions;
    },
  },

  mounted() {
    if (this.component.id === undefined) {
      if (
        !this.component.component_options ||
        !this.component?.component_options?.values
      ) {
        this.component.component_options = {
          values: {},
          multi: false,
        };
      }
    } else {
      let option_type = typeof this.component.component_options;
      if (option_type === "string") {
        this.component.component_options = JSON.parse(
          this.component.component_options
        );
      }
    }
    if (_.isEmpty(this.component.component_options)) {
      //initialize
      this.component.component_options = {
        values: {},
        multi: false,
      };
    }
  },
};
</script>

<style>
.optionBoolValue {
  border: 1px solid #ddd;
  height: 30px;
  max-width: 100px;
  padding: 3px;
}
</style>

<template>
  <div>
    <div
      class="d-flex flex-column q-detail-survey-item"
      :class="className"
      @click="onClick"
    >
      <survey-label
        :bold="true"
        :type="questionType.type"
        :icon="questionType.icon"
        :label="this.label || this.questionType.name || this.questionType.type"
      ></survey-label>

      <!-- CHECKBOX-->
      <template v-if="questionType.type === QUESTION_TYPES.CHECKBOX">
        <div class="d-flex">
          <input style="margin-top: 3px" disabled type="checkbox" checked />
          <small style="margin-top: 2px; margin-left: 2px; color: #666;"
            >opcion</small
          >
        </div>
      </template>

      <!-- SELECT-->
      <template v-if="questionType.type === QUESTION_TYPES.SELECT || questionType.type === QUESTION_TYPES.ACTION">
        <select disabled class="question-input-sample">
          <option selected>opcion</option>
        </select>
      </template>

      <!-- BOOL-->
      <!-- RADIO-->
      <template
        v-if="
          [QUESTION_TYPES.BOOL, QUESTION_TYPES.RADIO].includes(
            questionType.type
          )
        "
      >
        <div class="pt-1">
          <input type="radio" disabled />
          <label class="mx-1 my-0" style="color: #aaa;" :for="'radio_true'"
            >si</label
          >
          <input type="radio" disabled />
          <label class="mx-1 my-0" style="color: #aaa;" :for="'radio_false'"
            >no</label
          >
        </div>
      </template>

      <!-- DATE-->
      <template v-if="questionType.type === QUESTION_TYPES.DATE">
        <input
          class="question-input-sample"
          placeholder="2021-08-25"
          disabled
          type="text"
        />
      </template>

      <!-- NUMBER-->
      <template v-if="questionType.type === QUESTION_TYPES.NUMBER">
        <input
          class="question-input-sample"
          placeholder="128"
          disabled
          type="number"
        />
      </template>

      <!-- DECIMAL-->
      <template v-if="questionType.type === QUESTION_TYPES.DECIMAL">
        <input
          class="question-input-sample"
          placeholder="1.5"
          disabled
          type="number"
        />
      </template>

      <!-- LONG TEXT-->
      <template v-if="questionType.type === QUESTION_TYPES.LONGTEXT">
        <input
          class="question-input-sample"
          placeholder="ok"
          disabled
          type="text"
        />
      </template>

      <!-- STRING-->
      <template v-if="questionType.type === QUESTION_TYPES.STRING">
        <input
          class="question-input-sample"
          placeholder="ok"
          disabled
          type="text"
        />
      </template>

      <!-- MONEY-->
      <template v-if="questionType.type === QUESTION_TYPES.MONEY">
        <input
          class="question-input-sample"
          placeholder="12.8€"
          disabled
          type="text"
        />
      </template>

      <!-- PHOTO-->
      <template v-if="questionType.type === QUESTION_TYPES.PHOTO">
        <input
          class="question-input-sample"
          placeholder="upload photo"
          disabled
          type="text"
        />
      </template>
    </div>
  </div>
</template>

<script>
import surveyLabel from "@/components/survey/surveyLabel";
import { QUESTION_TYPES } from "../models";

export default {
  props: ["questionType", "label", "className"],

  components: {
    surveyLabel,
  },

  data() {
    return {
      QUESTION_TYPES,
    };
  },

  computed: {},

  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style>
.q-detail-survey-item {
  padding: 3px;
  font-size: 11px;
  margin: 2px 2.5px 2px 2.5px;
  background-color: #fff;
  border: 1px dashed #aaa;
  width: 140px;
  height: 45px;
}

.q-detail-survey-item:hover {
  cursor: pointer;
  border: 1px solid darkblue;
  background: aliceblue;
}

.question-input-sample {
  font-size: 11px;
  height: 20px;
}
.question-input-sample:hover {
  cursor: pointer;
}
</style>

<template>
  <fieldset class="m-0 p-2" v-if="question && question.options">
    <legend>
      <b-badge class="legend-title">Opciones Pregunta</b-badge>
    </legend>

    <div class="d-flex">
      <span class="h6 mr-1">Tipo:</span>
      <span class="h6">{{ question.survey_question_type.type }}</span>
    </div>
    <template
      v-if="
        [
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.ACTION
        ].includes(question.survey_question_type.type)
      "
    >
      <div
        v-if="
          [QUESTION_TYPES.BOOL].includes(question.survey_question_type.type) &&
            question.options?.values
        "
      >
        <div class="d-flex align-center my-2">
          <v-text-field
            v-model="optVal"
            label="Añadir Opcion"
            variant="outlined"
            dense
            hide-details="auto"
          ></v-text-field>

          <b-button
            @click="addBoolOpt(optVal)"
            size="sm"
            class="btn-small mr-1 no-radius"
            variant="outline-secondary"
          >
            <b-icon icon="plus-circle"></b-icon>
          </b-button>
        </div>
        <template
          v-for="(boolOptionKey, index) in Object.keys(
            question.options?.values
          )"
        >
          <div
            class="d-flex align-center mb-2"
            :key="`bool_option_${boolOptionKey}`"
          >
            <span>Value-{{ index + 1 }}: </span>
            <input
              class="optionBoolValue ml-1"
              type="text"
              v-model="question.options.values[boolOptionKey]"
            />
            <input
              type="number"
              class="optionBoolValue ml-1"
              placeholder="Real Value"
              v-if="question.options?.objectiveRealValues"
              v-model="question.options.objectiveRealValues[boolOptionKey]"
            />
            <input
              type="button"
              class="button-sm ml-2"
              value="X"
              @click="removeOpt(boolOptionKey)"
            />
          </div>
        </template>
        <div class="d-flex align-center mb-2">
          <span>Default: </span>
          <div class="d-flex flex-column ml-1" style="max-width: 200px">
            <v-autocomplete
              :items="boolValueList"
              v-model="question.options.default"
              item-text="label"
              item-value="value"
              filled
              dense
              hide-details="auto"
            ></v-autocomplete>
          </div>
        </div>
      </div>

      <div
        v-if="
          [QUESTION_TYPES.SELECT, QUESTION_TYPES.ACTION].includes(
            question.survey_question_type.type
          )
        "
      >
        <label class="mr-1">Multiples:</label>
        <input class="" type="checkbox" v-model="question.options.multi" />
      </div>
      <div
        v-if="
          [
            QUESTION_TYPES.CHECKBOX,
            QUESTION_TYPES.RADIO,
            QUESTION_TYPES.SELECT
          ].includes(question.survey_question_type.type)
        "
      >
        <div class="d-flex align-center my-2">
          <v-text-field
            v-model="optVal"
            label="Añadir Opcion"
            variant="outlined"
            dense
            hide-details="auto"
          ></v-text-field>

          <b-button
            @click="addOpt(optVal)"
            size="sm"
            class="btn-small mr-1 no-radius"
            variant="outline-secondary"
          >
            <b-icon icon="plus-circle"></b-icon>
          </b-button>
        </div>

        <div class="d-flex flex-column">
          <draggable
            :list="questionOrderedOptionKeys"
            group="question_options"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            @change="handleDragChange"
          >
            <transition-group type="transition" :class="``">
              <div
                class="border px-1 py-1 mb-1"
                style="color: blueviolet; cursor: pointer;"
                :key="`question_opt_values_${key}`"
                v-for="key in questionOrderedOptionKeys"
              >
                <div class="d-flex justify-content-between align-center">
                  {{ ellipsis(question.options.values[key], 70) }}

                  <input
                    type="number"
                    class="optionBoolValue ml-1"
                    placeholder="Real Value"
                    v-model="question.options.objectiveRealValues[key]"
                  />

                  <input
                    type="button"
                    class="button-sm "
                    value="X"
                    @click="removeOpt(key)"
                  />
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div
        v-if="
          [QUESTION_TYPES.ACTION].includes(question.survey_question_type.type)
        "
      >
        <div class="d-flex align-center my-2">
          <v-text-field
            v-model="optVal"
            label="Añadir Opcion"
            variant="outlined"
            dense
            hide-details="auto"
          ></v-text-field>

          <b-button
            @click="addActionOpt(optVal)"
            size="sm"
            class="btn-small mr-1 no-radius"
            variant="outline-secondary"
          >
            <b-icon icon="plus-circle"></b-icon>
          </b-button>
        </div>

        <div class="d-flex flex-column">
          <div
            class="px-1 py-1 d-flex justify-content-end"
            v-if="Object.keys(question.options?.values).length > 0"
          >
            <div style="width: 20%;" class=" pl-3">
              Is Action
            </div>
          </div>
          <div
            class="px-1 py-1 d-flex justify-content-between"
            :key="`question_opt_values_${key}`"
            v-for="(val, key) in question.options.values"
          >
            <div
              class="d-flex justify-content-between border px-1 py-2"
              style="color: blueviolet;width: 80%;"
            >
              {{ ellipsis(val?.label, 70) }}

              <input
                type="button"
                class="button-sm "
                value="X"
                @click="removeOpt(key)"
              />
            </div>
            <div
              class="d-flex justify-content-between pl-3"
              style="width: 20%;"
            >
              <v-checkbox
                v-model="val.isAction"
                dense
                hide-details="auto"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template
      v-else-if="
        [QUESTION_TYPES.DATE].includes(question.survey_question_type.type)
      "
    >
      <div
        class="d-flex align-center justify-content-between"
        v-if="question.options"
      >
        <div class="d-flex flex-column datepicker pr-1">
          <label class="m-0">desde:</label>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }"
            size="sm"
            locale="es"
            v-model="question.options.min"
            class="mb-2"
            menu-class="w-100"
          >
          </b-form-datepicker>
        </div>
        <div class="d-flex flex-column datepicker pl-1">
          <label class="m-0">hasta:</label>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }"
            size="sm"
            locale="es"
            v-model="question.options.max"
            class="mb-2"
            menu-class="w-100"
          >
          </b-form-datepicker>
        </div>
      </div>
    </template>
    <template
      v-else-if="
        ![
          QUESTION_TYPES.STRING,
          QUESTION_TYPES.LONGTEXT,
          QUESTION_TYPES.PHOTO
        ].includes(question.survey_question_type.type)
      "
    >
      <v-row class="my-2">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="question.options.min"
            label="MIN"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="question.options.max"
            label="MAX"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="d-flex justify-content-center">
            <b-button
              variant="outline-success"
              @click="onClickAddRange('', '')"
            >
              <b-icon icon="plus"></b-icon> Add range
            </b-button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="punctuationTableWrapper">
            <table border="1" class="text-center" style="width: 100%">
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Correct</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(rangeItem, rangeKey) in questionOptionRanges">
                  <tr :key="`range_row_${rangeKey}`">
                    <td>
                      <input
                        class="inputValue"
                        type="number"
                        v-model="rangeItem['fromValue']"
                        @change="onChangeRange(rangeKey, 'fromValue')"
                      />
                    </td>
                    <td>
                      <input
                        class="inputValue"
                        type="number"
                        v-model="rangeItem['toValue']"
                        @change="onChangeRange(rangeKey, 'toValue')"
                      />
                    </td>
                    <td>
                      <select
                        class="inputValue"
                        style="border: 1px solid #999"
                        v-model="rangeItem['isCorrect']"
                        @change="onChangeRange(rangeKey, 'isCorrect')"
                      >
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>
                    </td>
                    <td>
                      <b-button
                        variant="danger"
                        @click="onClickRemoveRange(rangeKey)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </template>
    <template
      v-else-if="
        [QUESTION_TYPES.PHOTO].includes(question.survey_question_type.type)
      "
    >
      <div class="d-flex mb-1 mt-0">
        <v-checkbox
          dense
          v-model="question.isRequiredPortalScore"
          :label="`Puntuación Obligatoria`"
          hide-details="auto"
        ></v-checkbox>
      </div>
      <div class="d-flex mb-1 mt-0">
        <v-checkbox
          dense
          v-model="question.isRequiredPortalComment"
          :label="`Comentarios Obligatorios`"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </template>
  </fieldset>
</template>

<script>
import draggable from "vuedraggable";
import { QUESTION_TYPES } from "../models";
import { ellipsis, logError } from "@/utils";
import _ from "lodash";

export default {
  props: ["value", "question"],

  components: {
    draggable
  },

  data() {
    return {
      QUESTION_TYPES: QUESTION_TYPES,
      optVal: null,
      questionOptionRanges: [],
      // isValid: false,
      isRangeValidation: false,

      editedQuestion: this.question,
      questionOrderedOptionKeys: []
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "questions",
        disabled: false
        //ghostClass: "ghost"
      };
    },
    isValid() {
      if (
        [
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.ACTION
        ].includes(this.question.survey_question_type.type)
      ) {
        return Object.keys(this.question.options.values).length > 0;
      } else if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER
        ].includes(this.question.survey_question_type.type)
      ) {
        if (this.questionOptionRanges.length === 0) return false;
        return this.isRangeValidation;
      }
      return true;
    },
    xType() {
      return this.selectedQuestion
        ? this.selectedQuestion.survey_question_type.type
        : null;
    },
    boolValueList() {
      if (
        [QUESTION_TYPES.BOOL].includes(this.question.survey_question_type.type)
      ) {
        let values = [
          {
            label: "None",
            value: null
          }
        ];
        for (const [key, value] of Object.entries(
          this.question.options.values
        )) {
          let push_item = {
            label: value,
            value: parseInt(key)
          };
          values.push(push_item);
        }
        return values;
      }
      return [];
    }
  },

  watch: {},

  methods: {
    ellipsis,

    handleDragChange(event) {
      console.log("New Order:", event, this.questionOrderedOptionKeys);
      if (this.question.options?.orderedKeys) {
        this.question.options.orderedKeys = this.questionOrderedOptionKeys;
      }
    },

    removeOpt(key) {
      this.question.options.values = _.omit(this.question.options.values, [
        key
      ]);
      this.question.options.objectiveRealValues = _.omit(
        this.question.options.objectiveRealValues,
        [key]
      );
      var index = this.question.options.orderedKeys.indexOf(key);
      if (index !== -1) {
        this.question.options.orderedKeys.splice(index, 1);
      }
    },

    addBoolOpt(val) {
      if (val === null) {
        logError("valores nulos no aceptados");
        return;
      }
      let keyList = Object.keys(this.editedQuestion.options.values);
      let insertKey = 1;
      if (keyList.length > 0) {
        let finalKey = keyList[keyList.length - 1];
        insertKey = parseInt(finalKey) + 1;
      }
      if (!Object.values(this.editedQuestion.options.values).includes(val)) {
        let optionValues = JSON.parse(
          JSON.stringify(this.question.options.values)
        );
        optionValues[`${insertKey}`] = val;
        this.editedQuestion.options.values = optionValues;

        let objectiveRealValues = JSON.parse(
          JSON.stringify(this.question.options.objectiveRealValues)
        );
        objectiveRealValues[`${insertKey}`] = null;
        this.editedQuestion.options.objectiveRealValues = objectiveRealValues;

        this.$emit("inputQuestion", this.editedQuestion);
      }
    },

    addOpt(val) {
      if (val === null) {
        logError("valores nulos no aceptados");
        return;
      }

      let keyList = Object.keys(this.question.options.values);
      let insertKey = 1;
      if (keyList.length > 0) {
        let finalKey = keyList[keyList.length - 1];
        insertKey = parseInt(finalKey) + 1;
      }
      if (!Object.values(this.question.options.values).includes(val)) {
        this.question.options.values[`${insertKey}`] = val;
        this.question.options.objectiveRealValues[`${insertKey}`] = null;
        let buffer_values = this.question.options.values;
        this.optVal = "";
        this.question.options.values = {};
        this.question.options.values = buffer_values;

        this.question.options.orderedKeys.push(insertKey);
      }
    },

    addActionOpt(val) {
      if (val === null) {
        logError("valores nulos no aceptados");
        return;
      }

      let keyList = Object.keys(this.question.options.values);
      let insertKey = 1;
      if (keyList.length > 0) {
        let finalKey = keyList[keyList.length - 1];
        insertKey = parseInt(finalKey) + 1;
      }
      if (!Object.values(this.question.options.values).includes(val)) {
        let pushItem = {
          label: val,
          value: val,
          isAction: false
        };
        this.question.options.values[`${insertKey}`] = pushItem;
        let buffer_values = this.question.options.values;
        this.optVal = "";
        this.question.options.values = {};
        this.question.options.values = buffer_values;
      }
    },

    onChangeRange(rangeIndex, rangeKey) {
      let isValid = true;
      let error_message = "";
      let changedValue = parseFloat(
        this.questionOptionRanges[rangeIndex][rangeKey]
      );
      this.questionOptionRanges[rangeIndex]["fromValue"] = parseFloat(
        this.questionOptionRanges[rangeIndex]["fromValue"]
      );
      this.questionOptionRanges[rangeIndex]["toValue"] = parseFloat(
        this.questionOptionRanges[rangeIndex]["toValue"]
      );
      if (
        this.questionOptionRanges[rangeIndex]["fromValue"] >
        this.questionOptionRanges[rangeIndex]["toValue"]
      ) {
        error_message = "El rango definido no está permitido!";
        isValid = false;
      }
      let minFromValue = this.question.options.min;
      let maxToValue = this.question.options.max;
      if (rangeIndex > 0) {
        minFromValue = parseFloat(
          this.questionOptionRanges[rangeIndex - 1]["toValue"]
        );
      }
      if (rangeIndex + 1 < this.questionOptionRanges.length) {
        maxToValue = parseFloat(
          this.questionOptionRanges[rangeIndex + 1]["fromValue"]
        );
      }
      if (
        parseFloat(this.questionOptionRanges[rangeIndex]["fromValue"]) <
        minFromValue
      ) {
        error_message = "El rango definido no está permitido!";
        isValid = false;
      }
      if (
        parseFloat(this.questionOptionRanges[rangeIndex]["toValue"]) >
        maxToValue
      ) {
        error_message = "El rango definido no está permitido!";
        isValid = false;
      }
      this.isRangeValidation = isValid;
      if (
        !isValid &&
        !_.isEmpty(this.questionOptionRanges[rangeIndex]["fromValue"]) &&
        !_.isEmpty(this.questionOptionRanges[rangeIndex]["toValue"])
      ) {
        logError(error_message);
      }
      // if (!isValid) {
      //   this.optimizeRanges(rangeIndex, rangeKey);
      // }
    },

    optimizeRanges(changedIndex, changedKey) {
      let changedValue = parseFloat(
        this.questionOptionRanges[changedIndex][changedKey]
      );
      if (changedKey === "fromValue") {
        this.questionOptionRanges[changedIndex][changedKey] = Math.max(
          changedValue,
          this.question.options.min
        );
      } else {
        this.questionOptionRanges[changedIndex][changedKey] = Math.min(
          changedValue,
          this.question.options.max
        );
      }
      for (let i = 0; i < this.questionOptionRanges.length; i++) {
        let currentItem = this.questionOptionRanges[i];
        currentItem.fromValue = parseFloat(currentItem.fromValue);
        currentItem.toValue = parseFloat(currentItem.toValue);
        let minValue = this.question.options.min;
        let maxValue = this.question.options.max;
        if (i === 0) {
          if (i + 1 < this.questionOptionRanges.length) {
            let nextItem = this.questionOptionRanges[i + 1];
            nextItem.fromValue = parseFloat(nextItem.fromValue);
            nextItem.toValue = parseFloat(nextItem.toValue);
            minValue = Math.max(currentItem.fromValue, minValue);
            maxValue = Math.min(
              currentItem.toValue,
              nextItem.fromValue,
              maxValue
            );
          } else {
            minValue = Math.max(currentItem.fromValue, minValue);
            maxValue = Math.min(currentItem.toValue, maxValue);
          }
        } else if (i === this.questionOptionRanges.length - 1) {
          let prevItem = this.questionOptionRanges[i - 1];
          prevItem.fromValue = parseFloat(prevItem.fromValue);
          minValue = Math.max(
            currentItem.fromValue,
            prevItem.toValue,
            minValue
          );
          maxValue = Math.min(currentItem.toValue, maxValue);
        } else if (i > 0 && i < this.questionOptionRanges.length - 1) {
          let prevItem = this.questionOptionRanges[i - 1];
          prevItem.fromValue = parseFloat(prevItem.fromValue);
          prevItem.toValue = parseFloat(prevItem.toValue);
          let nextItem = this.questionOptionRanges[i + 1];
          nextItem.fromValue = parseFloat(nextItem.fromValue);
          nextItem.toValue = parseFloat(nextItem.toValue);

          minValue = Math.max(
            currentItem.fromValue,
            prevItem.toValue,
            minValue
          );
          maxValue = Math.min(
            currentItem.toValue,
            nextItem.fromValue,
            maxValue
          );
        }
        if (!isNaN(minValue) && !isNaN(maxValue) && minValue > maxValue) {
          minValue = maxValue;
        }
        if (!isNaN(minValue)) {
          minValue = Math.max(this.question.options.min, minValue);
        } else {
          minValue = this.question.options.min;
        }
        if (!isNaN(maxValue)) {
          maxValue = Math.min(this.question.options.max, maxValue);
        } else {
          maxValue = this.question.options.max;
        }
        this.questionOptionRanges[i].fromValue = minValue;
        this.questionOptionRanges[i].toValue = maxValue;
      }
      this.convertStateRangeToPropRange();
    },

    onClickAddRange(initFrom, initTo) {
      let new_push_row = {
        fromValue: initFrom,
        toValue: initTo,
        isCorrect: false
      };
      let isVaild = true;
      let maxRangeKey = -1;
      for (const rangeItem of this.questionOptionRanges) {
        if (rangeItem.fromValue === "" || rangeItem.toValue === "") {
          isVaild = false;
        }
        if (maxRangeKey <= rangeItem.rangeKey) {
          maxRangeKey = parseInt(rangeItem.rangeKey);
        }
      }
      new_push_row.rangeKey = maxRangeKey + 1;
      if (isVaild) {
        this.questionOptionRanges.push(new_push_row);
        this.convertStateRangeToPropRange();
      }
    },

    convertStateRangeToPropRange() {
      let updatedRangeObject = {};
      for (const rangeItem of this.questionOptionRanges) {
        updatedRangeObject[rangeItem.rangeKey] = rangeItem;
      }
      this.question.options.ranges = updatedRangeObject;
    },

    onClickRemoveRange(rangeKey) {
      this.questionOptionRanges.splice(rangeKey, 1);
      this.convertStateRangeToPropRange();
    }
  },

  mounted() {
    if (this.question.id === undefined) {
      if (
        (!this.question.options || !this.question?.options?.values) &&
        this.question.survey_question_type.options
      ) {
        if (this.question.survey_question_type.type === QUESTION_TYPES.SELECT) {
          this.question.options = {
            values: {},
            multi: false
          };
          let optionKeyIndex = 1;
          for (const [optionKey, optionValue] of Object.entries(
            this.question.survey_question_type.options
          )) {
            this.question.options.values[optionKeyIndex] = optionValue;
            optionKeyIndex++;
          }
        } else if (
          [
            QUESTION_TYPES.BOOL,
            QUESTION_TYPES.CHECKBOX,
            QUESTION_TYPES.RADIO,
            QUESTION_TYPES.PHOTO
          ].includes(this.question.survey_question_type.type)
        ) {
          if (!_.isEmpty(this.question.survey_question_type.options)) {
            this.question.options = JSON.parse(
              JSON.stringify(this.question.survey_question_type.options)
            );
          } else {
            this.question.options = null;
          }
        } else if (
          ![
            QUESTION_TYPES.STRING,
            QUESTION_TYPES.LONGTEXT
            // QUESTION_TYPES.PHOTO,
          ].includes(this.question.survey_question_type.type)
        ) {
          if (!_.isEmpty(this.question.survey_question_type.options)) {
            this.question.options = JSON.parse(
              JSON.stringify(this.question.survey_question_type.options)
            );
          } else {
            this.question.options = null;
          }
          if (!this.question.options?.ranges) {
            this.question.options["ranges"] = {};
            this.questionOptionRanges = [];
          }
        } else {
          this.question.options = null;
        }
      }
    } else {
      let option_type = typeof this.question.options;
      if (option_type === "string") {
        this.question.options = JSON.parse(this.question.options);
      }
    }
    if (_.isEmpty(this.question.options)) {
      //initialize
      if (
        [QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.RADIO].includes(
          this.question.survey_question_type.type
        )
      ) {
        this.question.options = {
          values: {},
          orderedKeys: []
        };
      } else if (
        [QUESTION_TYPES.SELECT, QUESTION_TYPES.ACTION].includes(
          this.question.survey_question_type.type
        )
      ) {
        this.question.options = {
          values: {},
          multi: false,
          orderedKeys: []
        };
      } else if (
        [QUESTION_TYPES.BOOL].includes(this.question.survey_question_type.type)
      ) {
        this.question.options = {
          default: null,
          values: {
            1: "Yes",
            2: "No"
          }
        };
      } else if (
        [QUESTION_TYPES.DATE].includes(this.question.survey_question_type.type)
      ) {
        this.question.options = {
          min: "",
          max: ""
        };
      } else if (
        ![
          // QUESTION_TYPES.PHOTO,
          QUESTION_TYPES.STRING,
          QUESTION_TYPES.LONGTEXT
        ].includes(this.question.survey_question_type.type)
      ) {
        this.question.options = {
          min: 0,
          max: 99,
          ranges: {}
        };
        this.questionOptionRanges = [];
      } else {
        this.question.options = {};
      }
    }
    if (this.question.options?.ranges) {
      for (const [key, value] of Object.entries(this.question.options.ranges)) {
        let pushItem = { ...value, rangeKey: key };
        this.questionOptionRanges.push(pushItem);
      }
      this.isRangeValidation =
        Object.keys(this.question.options.ranges).length > 0;
    }
    if (
      !_.isEmpty(this.question.options) &&
      [
        QUESTION_TYPES.CHECKBOX,
        QUESTION_TYPES.RADIO,
        QUESTION_TYPES.SELECT,
        QUESTION_TYPES.BOOL,
        QUESTION_TYPES.ACTION
      ].includes(this.question.survey_question_type.type) &&
      !Object.keys(this.question.options).includes("objectiveRealValues")
    ) {
      this.question.options = {
        ...this.question.options,
        objectiveRealValues: {}
      };
      if (this.question.options?.values) {
        for (const [optionKey, optionValue] of Object.entries(
          this.question.options.values
        )) {
          this.question.options.objectiveRealValues[optionKey] = null;
        }
      }
    }
    if (
      !_.isEmpty(this.question.options) &&
      [
        QUESTION_TYPES.CHECKBOX,
        QUESTION_TYPES.RADIO,
        QUESTION_TYPES.SELECT,
        QUESTION_TYPES.BOOL,
        QUESTION_TYPES.ACTION
      ].includes(this.question.survey_question_type.type) &&
      (!Object.keys(this.question.options).includes("orderedKeys") ||
        !this.question.options?.orderedKeys) &&
      this.question.options?.values
    ) {
      this.question.options.orderedKeys = Object.keys(
        this.question.options?.values
      );
    }
    if (this.question?.options?.orderedKeys) {
      this.questionOrderedOptionKeys = this.question.options.orderedKeys;
    }
    console.log(
      "this.question.options.orderedKeys - ",
      this.question.options?.orderedKeys
    );
  }
};
</script>

<style>
.optionBoolValue {
  border: 1px solid #ddd;
  height: 30px;
  max-width: 100px;
  padding: 3px;
}
</style>

<template>
  <div class="d-flex flex-column">
    <v-dialog v-model="showConfirmationDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h6">No has guadado la encuesta.</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveLeaveRoute">
            {{ $t("save") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="leaveRoute">
            No {{ $t("save") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="showConfirmationDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="survey">
      <div
        class="border ml-2 px-2 py-2"
        style="width: 550px; padding-bottom: 3px"
      >
        <h5>
          <b-badge variant="primary"> {{ survey.brand.name }} </b-badge>
          {{ survey.name }}
        </h5>

        <div class="d-flex align-center justify-content-start">
          <router-link :to="`/surveys`" class="mr-2">
            <b-button class="mr-1 ml-1" variant="outline-secondary">
              <b-icon icon="arrow-left"></b-icon> volver
            </b-button>
          </router-link>

          <b-button
            @click="save"
            variant="outline-success"
            :disabled="isSaving"
            class="mr-2"
          >
            <b-spinner v-if="isSaving" small></b-spinner>
            <b-icon v-if="!isSaving" icon="cloud-upload"></b-icon> guardar
          </b-button>

          <span>Tiempo : </span>
          <b-form-timepicker
            v-model="estimatedTime"
            locale="es"
            style="max-width: 200px;"
            class="mx-2"
          ></b-form-timepicker>
        </div>
      </div>

      <div
        class="d-flex"
        style="overflow-x: scroll; height: calc(100vh - 120px);"
      >
        <fieldset class="input-box">
          <legend><b-badge class="legend-title">Inputs</b-badge></legend>

          <draggable
            :group="{
              name: 'questions',
              move: false,
              pull: 'clone',
              put: false
            }"
            :clone="onQuestionDrag"
            :list="questionTypes"
            :sort="false"
            class="d-flex flex-wrap justify-content-start"
          >
            <question-box
              :key="`survey_question_type_${key}`"
              v-for="(type, key) in questionTypes"
              :questionType="type"
              :label="type.label"
            ></question-box>
          </draggable>
        </fieldset>

        <div class="d-flex flex-column details">
          <fieldset>
            <legend><b-badge class="legend-title">Encuesta</b-badge></legend>

            <draggable
              :list="survey.survey_components"
              group="components"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                :key="`survey_component_${key}`"
                v-for="(component, key) in survey.survey_components"
                @click="editedComponent = component"
                class="mb-2"
              >
                <div class="d-flex justify-content-between">
                  <label class="my-0 component-title-item"
                    >{{ component.name ? component.name : component.uuid }}
                    <!-- <span class="puncvalue_badge">
                    {{ component.puncComValue ? component.puncComValue : 0 }}
                  </span> -->
                  </label>

                  <div class="d-flex">
                    <input
                      type="button"
                      class="small-button button-danger"
                      value="-eliminar"
                      @click="removeComponent(component)"
                    />

                    <!--                      <input type="button" class="small-button" style="background: #cccccc" value="+clonar" @click="cloneComponent(component)">-->
                  </div>
                </div>

                <draggable
                  :list="component.survey_questions"
                  group="questions"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  @add="onAdd"
                >
                  <transition-group
                    type="transition"
                    :class="
                      `d-flex flex-wrap component-edit-box ${
                        editedComponent &&
                        editedComponent.uuid === component.uuid
                          ? 'component-selected'
                          : ''
                      } ${component.uuid}`
                    "
                  >
                    <div
                      v-for="(item, key) in component.survey_questions"
                      :key="`survey_component_question_${key}`"
                      class="d-flex flex-column component-question-box"
                      :class="resolveClassQuestion(item)"
                      @click="editedQuestion = item"
                      style="position: relative;"
                    >
                      <label class="m-0" v-if="item.name">{{
                        ellipsis(item.name, 15)
                      }}</label>

                      <label class="m-0" style="color: #b4b3b3;" v-else
                        >sin nombre</label
                      >

                      <survey-label
                        :type="item.survey_question_type.type"
                        :icon="item.survey_question_type.icon"
                        :label="item.survey_question_type.type"
                      ></survey-label>
                      <!-- <span
                      class="puncvalue_badge"
                      style="position: absolute;right: 2px;bottom: 2px;"
                    >
                      {{ item.puncQueValue ? item.puncQueValue : 0 }}
                    </span> -->
                    </div>
                  </transition-group>
                </draggable>

                <b-button
                  size="sm"
                  class="btn-small mr-2"
                  variant="outline-primary"
                  @click="addComponent"
                >
                  <b-icon icon="plus-circle"></b-icon>
                  nuevo
                </b-button>
              </div>
            </draggable>

            <b-alert
              v-if="!survey.survey_components.length"
              class="m-0 p-2 my-alert"
              show
              ><i> Pulsa añadir un componente para empezar</i></b-alert
            >
          </fieldset>
        </div>

        <div class="d-flex flex-column details">
          <fieldset class="detail-box">
            <legend>
              <b-badge class="legend-title">Detalle Componente</b-badge>
            </legend>

            <component-detail
              :key="'comp_detail_' + editedComponent.uuid"
              v-if="editedComponent"
              :component="editedComponent"
              :otherComponents="survey.survey_components"
              :repeatsUnitValues="repeatsUnitValues"
              :assignTypes="assignTypes"
              :filterableFamilyAndSubBrands="filterableFamilyAndSubBrands"
            />

            <b-alert v-else variant="warning" class="m-0 p-2 my-alert" show
              ><i> Selecciona un elemento</i></b-alert
            >
          </fieldset>
        </div>

        <div class="d-flex flex-column details">
          <fieldset class="detail-box">
            <legend>
              <b-badge class="legend-title">Detalle Pregunta</b-badge>
            </legend>

            <question-detail
              :key="'quest_detail_' + editedQuestion.uuid"
              v-if="editedQuestion"
              :components="survey.survey_components"
              :editedComponent="editedComponent"
              :editedSurvey="survey"
              @remove="removeQuestion(editedQuestion)"
              @update="result => updateQuestion(result)"
              :question="editedQuestion"
              :repeatsUnitValues="repeatsUnitValues"
              :puncIncreaseTypes="puncIncreaseTypes"
              :puncVisitTypes="puncVisitTypes"
              :questionPhotoTypes="questionPhotoTypes"
              :questionImportTypes="questionImportTypes"
              :checkValidationCondItem="checkValidationCondItem"
              :checkForParenthesys="checkForParenthesys"
            />

            <b-alert v-else variant="warning" class="m-0 p-2 my-alert" show
              ><i> Selecciona una pregunta</i></b-alert
            >
          </fieldset>
        </div>
      </div>
    </template>

    <b-alert :show="!this.id" variant="warning" style="margin-top: 15px">
      Ninguna encuesta seleccionada o error al obtener datos del servidor:
      vuelve a la lista y selecciona una encuesta
      <router-link :to="`/config/surveys`">
        <b-button
          class="mr-1 ml-1 btn-small"
          variant="outline-secondary"
          size="sm"
        >
          <b-icon icon="arrow-left"></b-icon> volver
        </b-button>
      </router-link>
    </b-alert>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import questionBox from "./questionBox";
import questionDetail from "./questionDetail";
import componentDetail from "./componentDetail";

import { QuestionModel, ComponentModel } from "../models";
import surveyLabel from "@/components/survey/surveyLabel";
import { ellipsis, logWarning, logInfo, logError } from "@/utils";

import ApiService from "@/services/apiService";
import _ from "lodash";
import { orderByUUID } from "@/common.js";

const beforeunloadHandler = e => {
  e.preventDefault();
  const confirmationMessage = "No has guadado la encuesta.";
  e.returnValue = confirmationMessage;
  console.log("survey editor - beforeunloadHandler", e);
};

const unloadHandler = async () => {
  console.log("survey editor - unloadHandler");
};

export default {
  name: "SurveyEditor",
  components: {
    draggable,
    questionBox,
    questionDetail,
    componentDetail,
    surveyLabel
  },
  data() {
    return {
      id: null,

      survey: null,

      questionTypes: [],

      editedQuestion: null,

      editedComponent: null,

      repeatsUnitValues: [],
      puncIncreaseTypes: [],
      puncVisitTypes: [],
      assignTypes: [],

      isSaving: false,
      estimatedTime: null,

      showConfirmationDialog: false,

      filterableFamilyAndSubBrands: {},
      questionPhotoTypes: {},
      questionImportTypes: {}
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "questions",
        disabled: false
        //ghostClass: "ghost"
      };
    }
  },
  methods: {
    isFormDirty() {
      // Implement your logic to check if there are any unsaved changes
      // For example, you can compare the current form values with the initial values
      // Return true if there are unsaved changes, false otherwise
      return true;
    },
    leaveRoute() {
      // User confirmed, proceed with navigation
      this.showConfirmationDialog = false;
      this.nextRoute();
    },
    async saveLeaveRoute() {
      this.showConfirmationDialog = false;
      let saveResult = await this.onlySave();
      if (saveResult) {
        this.nextRoute();
      }
    },
    async save() {
      let saveResult = await this.onlySave();
      if (saveResult) {
        window.removeEventListener("beforeunload", beforeunloadHandler);
        window.removeEventListener("unload", unloadHandler);
        document.location.reload();
      }
    },

    checkForParenthesys(expression, calculations, isShow = true) {
      let validation = this.isValidationForParenthesysCalc(
        expression,
        calculations
      );

      if (isShow) {
        if (validation === 0) {
          logWarning("Error Parenthesys-Calculation!");
        } else if (validation === 1) {
          logWarning(
            "Error Parenthesys-Calculation! You should check the order of component/questions in the parenthesys string."
          );
        } else if (validation === 2) {
          logWarning("Error Parenthesys-Calculation! Expression Error");
        } else if (validation === 3) {
          logWarning("Error Parenthesys-Calculation! Bracket Error");
        } else if (validation === 4) {
          logWarning("Error Parenthesys-Calculation! Operator Error");
        }
      }
      return validation;
    },

    isNumber(character) {
      return /^\d$/.test(character);
    },

    // 0: , 1: Increment Error, 2: Expression Error, 3: Bracket Error, 4: Operator Error, 5: OK
    isValidationForParenthesysCalc(expression, calculations) {
      // Expression should consist of only four-basic-operators, parenthesys spaces and numbers, *+-/() 0123456789
      const regex = /^[+\-*/() \d]+$/;
      if (!regex.test(expression)) return 0;

      // Check for validation
      let countBracket = 0,
        comp = 1,
        lastChar = "",
        operators = [];
      for (let i = 0; i < expression.length; i++) {
        let chr = expression[i];
        if (chr === "*" || chr === "+" || chr === "-" || chr === "/") {
          if (this.isNumber(lastChar) || lastChar === ")") {
            lastChar = chr;
            operators.push(chr);
            continue;
          }
          return 2;
        } else if (chr === "(") {
          if (this.isNumber(lastChar) || lastChar === ")") {
            return 2;
          }
          lastChar = chr;
          countBracket++;
        } else if (chr === ")") {
          if (this.isNumber(lastChar) || lastChar === ")") {
            countBracket--;
            lastChar = chr;
            if (countBracket < 0) return 3;
            continue;
          }
          return 2;
        } else if (this.isNumber(chr)) {
          if (lastChar === ")") {
            return 2;
          }
          let num = 0;
          while (i < expression.length && this.isNumber(expression[i]))
            (num = num * 10 + parseInt(expression[i])), i++;
          lastChar = chr;
          if (comp !== num) return 1;
          comp++;
          i--;
        }
      }
      if (!this.isNumber(lastChar) && lastChar !== ")") return 2;
      if (calculations.length !== comp - 1) return 1;
      for (let i = 0; i < operators.length; i++) {
        if (
          operators[i] !==
          this.getOperatorsWithName(
            calculations[i + 1]["integrationOfOperation"]
          )
        )
          return 4;
      }
      return countBracket === 0 ? 5 : 3;
    },

    getOperatorsWithName(operatorName) {
      if (operatorName === "SUBTRACT") return "-";
      if (operatorName === "SUM") return "+";
      if (operatorName === "MULTIPLY") return "*";
      if (operatorName === "DIVIDE") return "/";
      return null;
    },

    checkValidationCondItem(calcItem) {
      if (!calcItem) return false;
      const {
        format,
        multipler,
        operation,
        component,
        question,
        // options,
        // values,
        integrationOfOperation
      } = calcItem;
      let isAddFailed = true;
      if (
        !format ||
        !multipler ||
        !operation ||
        !component ||
        !question ||
        // !options ||
        // !values ||
        !integrationOfOperation
      ) {
        isAddFailed = false;
      }
      return isAddFailed;
    },
    checkValidationForCalculation(question) {
      let isValidation = true;
      if (question) {
        if (
          question?.questionImportType === "CALCULATION_DATA" &&
          question?.calculations
        ) {
          for (const calcItem of question.calculations) {
            if (!this.checkValidationCondItem(calcItem)) {
              isValidation = false;
              break;
            }
          }
        }
      }
      return isValidation;
    },
    async onlySave() {
      for (const comp of this.survey.survey_components) {
        // if (comp.survey_questions.length === 0) {
        //   logWarning("Hay componentes vacios, revisa antes de guardar");
        //   return;
        // }
        if (!this.isComponentCompleted(comp)) {
          logWarning("Hay componentes incompletos, revisa antes de guardar");
          return;
        }
        if (comp?.survey_questions && comp?.survey_questions?.length > 0) {
          for (const q of comp.survey_questions) {
            if (
              q?.questionImportType === 'CALCULATION_DATA' &&
              q?.calcParenthesys !== null &&
              q?.calculations &&
              this.checkForParenthesys(q.calcParenthesys, q.calculations) !== 5
            ) {
              return;
            }

            if (!this.isQuestionCompleted(q)) {
              logWarning("Hay preguntas incompletas. Revisa antes de guardar");
              return;
            }
            if (!this.checkValidationForCalculation(q)) {
              logWarning("Error Calculation - valores nulos no aceptados !");
              return;
            }
          }
        }
      }
      console.log(
        "this.survey.survey_components - ",
        this.survey.survey_components
      );
      this.isSaving = true;
      try {
        await ApiService.put(`/surveys/${this.id}/components`, {
          components: this.survey.survey_components,
          estimatedTime: this.estimatedTime
        });
        logInfo("Encuesta guardada");
        this.isSaving = false;
        return true;
      } catch (e) {
        logError("Error al guardar encuesta: " + e.toString());
        this.isSaving = false;
        return false;
      }
    },
    addComponent() {
      const comp = new ComponentModel({ surveyId: this.id });
      this.survey.survey_components.push(comp);
      window.setTimeout(() => {
        this.editedComponent = this.survey.survey_components[
          this.survey.survey_components.length - 1
        ];
        this.editedQuestion = null;
      }, 500);
    },

    onAdd(event) {
      if (event && Object.keys(event).includes("newIndex") && event?.target) {
        console.log("onAdd:", event);
        let questionIndex = event.newIndex;
        let className = event.target.className;
        className = className.replaceAll(
          "d-flex flex-wrap component-edit-box",
          ""
        );
        className = className.replaceAll("component-selected", "");
        let selectedCompUUID = className.replaceAll(" ", "");
        console.log(selectedCompUUID, questionIndex);
        let selectedComp = this.survey.survey_components.find(
          el => el.uuid === selectedCompUUID
        );
        if (selectedComp) {
          this.editedComponent = selectedComp;
          this.editedQuestion = this.editedComponent.survey_questions[
            questionIndex
          ];
        }
      }
    },

    ellipsis,

    resolveClassQuestion(item) {
      if (this.editedQuestion && this.editedQuestion?.uuid === item?.uuid) {
        return "component-question-selected";
      }
      return this.isQuestionCompleted(item)
        ? "component-question-ok"
        : "component-question-ko";
    },

    isQuestionCompleted(item) {
      return !!item.name;
    },

    isComponentCompleted(item) {
      return !!item.name;
    },

    removeComponent(component) {
      if (window.confirm("Estas seguro que quieres eliminar el componente?")) {
        this.survey.survey_components = this.survey.survey_components.filter(
          c => c.uuid !== component.uuid
        );
      }
    },

    updateQuestion(result) {
      this.editedQuestion = result;
    },

    removeQuestion(question) {
      this.survey.survey_components.forEach(comp => {
        comp.survey_questions = comp.survey_questions.filter(
          q => q.uuid !== question.uuid
        );
      });
      this.editedQuestion = null;
    },

    onQuestionDrag(type) {
      return new QuestionModel({
        // name: type.primary ? null : type.name,
        name: type.label,
        survey_question_type: type,
        isRemember: true,
        isMandatory: true,
        questionImportType: "SURVEY_DATA",
        isAlwaysEditing: false,
        isSumPS: true,
        isSales: false
      });
    },

    async init() {
      let resp = await ApiService.get("/opt/surveys");
      this.questionTypes = resp.data;
      this.repeatsUnitValues = resp.repeatsUnitValues;
      this.id = this.$route.params.id;

      resp = await ApiService.get(`/surveys/${this.id}`);

      this.survey = resp.data;
      this.puncIncreaseTypes = resp.puncIncreaseTypes;
      this.puncVisitTypes = resp.puncVisitTypes;
      this.assignTypes = resp.assignTypes;
      this.estimatedTime = this.survey.estimatedTime;
      this.filterableFamilyAndSubBrands = resp.filterableFamilyAndSubBrands;
      this.questionPhotoTypes = resp.questionPhotoTypes;
      this.questionImportTypes = resp.questionImportTypes;

      //sorting components and questions by uuid list
      this.survey.survey_components = orderByUUID(
        this.survey.survey_components,
        this.survey.componentOrder
      );
      console.log(
        "this.survey.survey_components - ",
        this.survey.survey_components
      );
      this.survey.survey_components.forEach(
        component =>
          (component.survey_questions = orderByUUID(
            component.survey_questions,
            component.questionOrder
          ))
      );
      console.log(
        "this.survey.survey_components - ",
        this.survey.survey_components
      );

      if (!this.survey.survey_components.length) this.addComponent();

      this.editedComponent = this.survey.survey_components[0];

      this.editedQuestion = _.get(this.editedComponent, "survey_questions[0]");
    }
  },

  async beforeDestroy() {
    window.removeEventListener("beforeunload", beforeunloadHandler);
    window.removeEventListener("unload", unloadHandler);
  },
  async mounted() {
    window.addEventListener("beforeunload", beforeunloadHandler);
    window.addEventListener("unload", unloadHandler);
    await this.init();
  },

  beforeRouteLeave(to, from, next) {
    // Check if the form is dirty or any unsaved changes exist
    if (this.isFormDirty()) {
      // Show the confirmation dialog
      this.showConfirmationDialog = true;

      // Store the next route to navigate to
      this.nextRoute = next;
    } else {
      // No unsaved changes, proceed with navigation
      next();
    }
  }
};
</script>

<style>
.component-question-selected,
.component-question-selected:hover {
  border: 1.5px solid darkblue !important;
  background: aliceblue !important;
}

.component-selected {
  border: 1px solid darkblue !important;
  background: #f8feff;
}

.details {
  font-size: 12px;
}

.input-box {
  flex-shrink: 0;
  width: 310px;
}

.survey-box {
  flex-shrink: 0;
  /*width: 716px;*/
}

.component-title-item {
  font-size: 12px;
}

.component-question-box {
  padding: 3px;
  font-size: 11px;
  margin: 2px;
  background-color: #fff;
  border: 1px dashed #aaa;
  width: 110px;
  height: 45px;
}

.component-question-box:hover {
  cursor: pointer;
  border: 1px solid darkblue;
  background: aliceblue;
}

.component-question-box label {
  cursor: pointer;
}

.component-question-ko {
  /*background: #fff6f6;*/
  border-color: red;
}

.component-question-ok {
  background: white;
  border: 1px solid green;
}

.component-edit-box {
  padding: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px dashed;
  min-height: 106px;
  width: 693px;
}

.detail-box {
  font-size: 12px;
  flex-shrink: 0;
  background: #f8f8f8;
  width: 370px;
}

.divider {
  margin: 5px 0px 5px 0px;
  border-top: 3px solid #545f89;
}

.main-container {
  width: 1400px;
  overflow-x: scroll;
}

.legend-title {
  font-size: 13px;
  font-weight: normal;
}

.puncvalue_badge {
  background: #bbb;
  border-radius: 5px;
  font-weight: 800;
  min-width: 20px;
  text-align: center;
  padding: 3px 7px;
}
</style>

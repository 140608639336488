<template>
  <div class="d-flex flex-column" v-if="component">
    <div class="d-flex mb-1" style="display: none !important">
      <label class="mr-1">id:</label>
      <b-badge
        v-if="!component.id"
        variant="danger"
        class="mt-1 mx-1"
        style="height: 16px;"
        >N/A</b-badge
      >
      {{ component.id }}
    </div>

    <div class="d-flex mb-1">
      <label class="mr-1">uuid:</label>{{ component.uuid }}
    </div>

    <div class="d-flex mt-3">
      <v-text-field
        v-model="component.name"
        v-bind:label="$t('brands.name')"
        variant="outlined"
        dense
        hide-details="auto"
      ></v-text-field>
    </div>

    <div class="d-flex mt-3">
      <v-checkbox
        dense
        v-model="component.hideLabel"
        label="ocultar nombre"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mt-3">
      <imageUploader
        :imageCategory="`COMPONENT`"
        :data="component"
        @onChangePhotoId="
          (photoId, imagePreviewUrl) =>
            onChangePhotoId(photoId, imagePreviewUrl)
        "
        @removePhotoId="removePhotoId"
      />
    </div>

    <div class="d-flex">
      <v-checkbox
        dense
        v-model="component.hide"
        label="desactivar?"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex flex-column datepicker">
        <label class="m-0">Desde:</label>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          size="sm"
          locale="es"
          v-model="component.activeDateFrom"
          class="mb-2"
        >
        </b-form-datepicker>
      </div>

      <div class="d-flex flex-column datepicker">
        <label class="m-0">Hasta:</label>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          size="sm"
          locale="es"
          v-model="component.activeDateTo"
          class="mb-2"
        >
        </b-form-datepicker>
      </div>
    </div>

    <div class="d-flex mb-0">
      <v-checkbox
        dense
        v-model="component.repeats"
        label="se repite?"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="component.isAction"
        label="Acción"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <template v-if="component.repeats">
      <div class="d-flex align-center px-3">
        <v-text-field
          class="mr-1"
          type="number"
          v-model="component.repeatsValue"
          v-bind:label="$t('Repeat Number')"
          dense
          hide-details="auto"
        ></v-text-field>
      </div>
      <div class="d-flex align-center mt-2 mb-2 px-3">
        <v-autocomplete
          v-model="component.repeatsUnit"
          v-bind:label="$t('Repeat Frequency')"
          :items="repeatsUnitValues"
          item-text="label"
          item-value="value"
          filled
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
    </template>

    <div class="m-0">
      <comConditionalBuilderV2
        :components="otherComponents"
        :editedComponent="component"
        class="mt-1"
      ></comConditionalBuilderV2>
    </div>

    <fieldset class="m-0 mt-2 p-2 ">
      <div class="d-flex align-center justify-content-center mb-0">
        <v-radio-group row v-model="component.assignType">
          <template v-for="(assignTypeItem, index) in assignTypes">
            <v-radio
              :key="index"
              :value="assignTypeItem.value"
              :label="assignTypeItem.label"
            ></v-radio>
          </template>
        </v-radio-group>
      </div>
      <div
        class="d-flex align-center justify-content-center mb-0"
        v-if="component.assignType === 'OTHERS'"
      >
        <v-checkbox
          dense
          v-model="component.isOthersBottom"
          label="Otros Bottom"
          hide-details="auto"
        ></v-checkbox>
      </div>
    </fieldset>

    <div class="m-0" v-if="component.assignType === 'OTHERS'">
      <comOptionsBuilder :component="component" class="mt-1" />
    </div>

    <fieldset class="m-0 mt-2 p-2">
      <v-row
        class="mt-2"
        v-for="(optionItem, optionKey) in filterOptions"
        :key="`filter_family_subbrand_${optionKey}`"
      >
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="component[optionItem.valueKey]"
            v-bind:label="optionItem.label"
            :items="filterableFamilyAndSubBrands[optionItem.key]"
            :item-text="optionItem.optionLabel"
            :item-value="optionItem.optionKey"
            dense
            hide-details="auto"
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
    </fieldset>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { uniqBy } from "lodash";
import { ellipsis } from "@/utils";
import Multiselect from "vue-multiselect";
import ApiService from "@/services/apiService";
// import comConditionalBuilder from "./comConditionalBuilder";
import comConditionalBuilderV2 from "./comConditionalBuilderV2";
import comOptionsBuilder from "./comOptionsBuilder";
import imageUploader from "./imageUploader";

export default {
  props: [
    "component",
    "otherComponents",
    "repeatsUnitValues",
    "assignTypes",
    "filterableFamilyAndSubBrands",
  ],
  components: {
    Multiselect,
    // comConditionalBuilder,
    comConditionalBuilderV2,
    imageUploader,
    comOptionsBuilder,
  },
  data() {
    return {
      filterOptions: [
        {
          label: `Sub ${this.$t("brands.brand")}`,
          key: "subBrands",
          optionKey: "brand_id",
          optionLabel: "name",
          valueKey: "sub_brand_ids",
        },
        {
          label: `Sub Sub ${this.$t("brands.brand")}`,
          key: "sub2Brands",
          optionKey: "brand_id",
          optionLabel: "name",
          valueKey: "sub2_brand_ids",
        },
        {
          label: `${this.$t("brands.family")}`,
          key: "families",
          optionKey: "id",
          optionLabel: "name",
          valueKey: "main_family_ids",
        },
        {
          label: `Sub ${this.$t("brands.family")}`,
          key: "subFamilies",
          optionKey: "family_id",
          optionLabel: "name",
          valueKey: "sub_family_ids",
        },
        {
          label: `Sub Sub ${this.$t("brands.family")}`,
          key: "sub2Families",
          optionKey: "family_id",
          optionLabel: "name",
          valueKey: "sub2_family_ids",
        },
        {
          label: `${this.$t("brands.family")} Material`,
          key: "materialFamilies",
          optionKey: "id",
          optionLabel: "name",
          valueKey: "mat_main_family_ids",
        },
        {
          label: `Sub ${this.$t("brands.family")} Material`,
          key: "materialSubFamilies",
          optionKey: "id",
          optionLabel: "name",
          valueKey: "mat_sub_family_ids",
        },
      ],
    };
  },

  watch: {},

  computed: {},

  methods: {
    ellipsis,
    onChangePhotoId(photoId, imagePreviewUrl) {
      this.component.photoId = photoId;
      this.component.imagePreviewUrl = imagePreviewUrl;
    },
    removePhotoId() {
      this.component.photoId = null;
      this.component.imagePreviewUrl = null;
      this.component.surveyComponentPhoto = null;
    },
  },

  created() {
    this.component.products = this.component.products || [];
    this.component.materials = this.component.materials || [];
  },
};
</script>

<style>
.datepicker {
  width: 172px;
}

.cd-select {
  width: 145px;
}
</style>

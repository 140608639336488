<template>
  <div>
    <modal
      name="modal-perfect-store-detail"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">Perfect Store Detail</span>
          </v-card-text>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="downloadExcelTemplate()"
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                  Plantilla Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelecting"
                  @click="onUploadExcelClick"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ buttonTextUploadExcel }}
                </v-btn>
                <input
                  ref="uploader_perfect_store_details"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedUploadingExcel"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadExcelPerfectStoreDetails"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="punctuationTableWrapper">
                  <table border="1" class="text-center" style="width: 100%">
                    <thead
                      v-if="
                        (assignTypeValue !== 'OTHERS' ||
                          (assignTypeValue === 'OTHERS' &&
                            componentOptions.length > 0)) &&
                          ![
                            QUESTION_TYPES.LONGTEXT,
                            QUESTION_TYPES.STRING,
                            QUESTION_TYPES.DATE
                          ].includes(editedQuestion?.survey_question_type.type)
                      "
                    >
                      <tr>
                        <th rowspan="2">&nbsp;</th>
                        <template v-for="(columnItem, colIndex) in columns">
                          <th
                            v-if="columnItem.key !== 'pro_mat_option_col'"
                            :key="`parent_col_${colIndex}`"
                            :colspan="`${columnItem.children.length}`"
                          >
                            {{ columnItem.title }}
                          </th>
                        </template>
                      </tr>
                      <tr>
                        <template v-for="columnItem in columns">
                          <template v-for="subColItem in columnItem.children">
                            <th :key="`sub_col_${subColItem.key}`">
                              {{ subColItem.title }}
                            </th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <thead v-else>
                      <tr>
                        <template v-for="(columnItem, colIndex) in columns">
                          <th :key="`${colIndex}`">
                            {{ columnItem.title }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        (assignTypeValue !== 'OTHERS' ||
                          (assignTypeValue === 'OTHERS' &&
                            componentOptions.length > 0)) &&
                          ![
                            QUESTION_TYPES.LONGTEXT,
                            QUESTION_TYPES.STRING,
                            QUESTION_TYPES.DATE
                          ].includes(editedQuestion?.survey_question_type.type)
                      "
                    >
                      <template v-for="(record, recordIndex) in tableData">
                        <tr :key="recordIndex">
                          <td>{{ record["pro_mat_option_col"] }}</td>
                          <template v-for="columnItem in columns">
                            <template v-for="subColItem in columnItem.children">
                              <td :key="`sub_col_${subColItem.key}`">
                                <input
                                  class="inputValue"
                                  type="number"
                                  v-if="
                                    Object.keys(record).includes(subColItem.key)
                                  "
                                  v-model="record[subColItem.key]"
                                  @input="
                                    onChangePuncValues(record, subColItem.key)
                                  "
                                />
                              </td>
                            </template>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <template v-for="(record, recordIndex) in tableData">
                        <tr :key="recordIndex">
                          <td>{{ record["pro_mat_option_col"] }}</td>
                          <template v-for="columnItem in columns">
                            <td
                              :key="`sub_col_${columnItem.key}`"
                              v-if="columnItem.key !== 'pro_mat_option_col'"
                            >
                              <input
                                class="inputValue"
                                type="number"
                                v-model="record[columnItem.key]"
                                v-if="
                                  Object.keys(record).includes(columnItem.key)
                                "
                                @input="
                                  onChangePuncValues(record, columnItem.key)
                                "
                              />
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="saveTableData()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import { QUESTION_TYPES } from "../models";

export default {
  name: "DialogPerfectStoreDetail",
  props: [
    "editedSurvey",
    "editedComponent",
    "editedQuestion",
    "saveCurrentPuncDetails"
  ],
  components: {},
  data: function() {
    return {
      QUESTION_TYPES: QUESTION_TYPES,
      chainList: [],
      materials: [],
      products: [],
      componentOptions: [],
      detail_value_id_list: [],
      perfectStoreDetails: null,
      assignTypeValue: null,

      tableData: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      showEmpty: false,
      columns: [],
      defaultSubColumnItem: {
        title: "",
        key: `chainValue`,
        field: `chainValue`,
        edit: true,
        width: 70
      },
      defaultSubColumns: [
        {
          title: "Yes",
          key: `chainValue_Y`,
          field: `chainValue_Y`,
          edit: true,
          width: 70
        },
        {
          title: "No",
          key: `chainValue_N`,
          field: `chainValue_N`,
          edit: true,
          width: 70
        }
      ],

      isSelecting: false,
      selectedFile: null,
      defaultButtonTextUploadExcel: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple"
    };
  },
  watch: {},
  computed: {
    buttonTextUploadExcel() {
      return this.isSelecting
        ? "Uploading..."
        : this.defaultButtonTextUploadExcel;
    },
    surveyQuestionType() {
      let ret = null;
      if (this.editedQuestion && this.editedQuestion?.survey_question_type) {
        ret = this.editedQuestion?.survey_question_type.type;
      }
      return ret;
    }
  },
  methods: {
    showModal(
      chainList,
      materials,
      products,
      componentOptions,
      detail_value_id_list
    ) {
      this.init(
        chainList,
        materials,
        products,
        componentOptions,
        detail_value_id_list
      );
      this.$modal.show("modal-perfect-store-detail");
    },
    hideModal() {
      this.$modal.hide("modal-perfect-store-detail");
    },
    getChainColTitle(surveyChainItem) {
      if (surveyChainItem.id === "default") {
        return "Default";
      } else {
        let titles = [];
        if (surveyChainItem?.ChainPerSurvey) {
          titles.push(surveyChainItem?.ChainPerSurvey.name);
        }
        if (surveyChainItem?.subChainPerSurvey) {
          titles.push(surveyChainItem?.subChainPerSurvey.name);
        }
        if (surveyChainItem?.sub2ChainPerSurvey) {
          titles.push(surveyChainItem?.sub2ChainPerSurvey.name);
        }
        return titles.join(" / ");
      }
    },
    generate_new_cols() {
      this.columns = [];
      let pro_mat_option_col = {
        title: "",
        key: "pro_mat_option_col",
        field: "pro_mat_option_col",
        fixed: "left",
        width: 150
      };
      this.columns.push(pro_mat_option_col);
      let newChainList = [{ id: "default" }, ...this.chainList];
      newChainList.map(chainItem => {
        let pushChainItem = {
          title: `${chainItem.id}`,
          key: chainItem.id,
          children: []
        };
        pushChainItem.title = this.getChainColTitle(chainItem);
        if (
          (this.editedQuestion?.perfectStoreDetails?.assignType !== "OTHERS" ||
            (this.editedQuestion?.perfectStoreDetails?.assignType ===
              "OTHERS" &&
              this.componentOptions.length > 0)) &&
          ![
            QUESTION_TYPES.LONGTEXT,
            QUESTION_TYPES.STRING,
            QUESTION_TYPES.DATE
          ].includes(this.editedQuestion?.survey_question_type.type)
        ) {
          if (this.editedQuestion.options?.values) {
            for (const [optionKey, optionValue] of Object.entries(
              this.editedQuestion.options.values
            )) {
              let subItem = { ...this.defaultSubColumnItem };
              subItem.title = optionValue;
              if (
                this.editedQuestion?.survey_question_type.type ===
                QUESTION_TYPES.PHOTO
              ) {
                subItem.title = optionKey;
              }
              if (
                QUESTION_TYPES.ACTION ===
                this.editedQuestion?.survey_question_type.type
              ) {
                subItem.title = optionValue.label;
              }
              subItem.key += `_${optionKey}_${chainItem.id}`;
              subItem.field += `_${optionKey}_${chainItem.id}`;
              pushChainItem.children.push(subItem);
            }
          } else if (this.editedQuestion.options?.ranges) {
            for (const [optionKey, optionValue] of Object.entries(
              this.editedQuestion.options.ranges
            )) {
              let subItem = { ...this.defaultSubColumnItem };
              subItem.title = `${optionValue.fromValue} - ${optionValue.toValue}`;
              subItem.key += `_${optionKey}_${chainItem.id}`;
              subItem.field += `_${optionKey}_${chainItem.id}`;
              pushChainItem.children.push(subItem);
            }
          }
        } else {
          pushChainItem.key = `chainValue_otherValue_${chainItem.id}`;
        }
        this.columns.push(pushChainItem);
        return chainItem;
      });
    },
    generate_table_data(perfectStoreDetails) {
      if (perfectStoreDetails) {
        const assignTypeValue = perfectStoreDetails.assignType;
        let currentDetails = perfectStoreDetails.values[assignTypeValue];
        let currentSelectedData = [];
        let isRangeForType = false;
        if (assignTypeValue === "PRODUCT") {
          currentSelectedData = this.products;
        } else if (assignTypeValue === "MATERIAL") {
          currentSelectedData = this.materials;
        } else {
          if (this.componentOptions.length > 0) {
            currentSelectedData = this.componentOptions.map(el => {
              return { ...el, name: el.optionLabel };
            });
          } else {
            if (
              [
                QUESTION_TYPES.DATE,
                QUESTION_TYPES.STRING,
                QUESTION_TYPES.LONGTEXT
              ].includes(this.editedQuestion.survey_question_type.type)
            ) {
              currentSelectedData = {};
            } else {
              if (this.editedQuestion.options?.values) {
                currentSelectedData = this.editedQuestion.options.values;
                isRangeForType = false;
              } else if (this.editedQuestion.options?.ranges) {
                currentSelectedData = this.editedQuestion.options.ranges;
                isRangeForType = true;
              }
            }
          }
        }

        this.tableData = [];
        for (const [detailId, detailValue] of Object.entries(currentDetails)) {
          let tableRecord = { rowKey: null };
          tableRecord.detailId = detailId;
          tableRecord.pro_mat_option_col = "";
          if (detailId === "default") {
            tableRecord.pro_mat_option_col = "default";
          } else {
            if (assignTypeValue !== "OTHERS") {
              let selectedProMatOptionItem = currentSelectedData.find(
                el => parseInt(el.id) === parseInt(detailId)
              );
              if (selectedProMatOptionItem) {
                tableRecord.pro_mat_option_col = selectedProMatOptionItem.name;
              }
            } else if (
              assignTypeValue === "OTHERS" &&
              this.componentOptions.length > 0
            ) {
              let selectedProMatOptionItem = currentSelectedData.find(
                el => parseInt(el.optionValue) === parseInt(detailId)
              );
              if (selectedProMatOptionItem) {
                tableRecord.pro_mat_option_col = selectedProMatOptionItem.name;
              }
            } else {
              let typeOfCurrentSelectedData = typeof currentSelectedData;

              if (Object.keys(currentSelectedData).length > 0) {
                if (!isRangeForType) {
                  tableRecord.pro_mat_option_col =
                    currentSelectedData[detailId];
                  if (
                    QUESTION_TYPES.ACTION ===
                    this.editedQuestion?.survey_question_type.type
                  ) {
                    tableRecord.pro_mat_option_col =
                      currentSelectedData[detailId].label;
                  } else if (
                    QUESTION_TYPES.PHOTO ===
                    this.editedQuestion?.survey_question_type.type
                  ) {
                    tableRecord.pro_mat_option_col = detailId;
                  }
                } else {
                  tableRecord.pro_mat_option_col = `${currentSelectedData[detailId]["fromValue"]} - ${currentSelectedData[detailId]["toValue"]}`;
                }
              } else {
                tableRecord.pro_mat_option_col = "default";
              }
            }
          }

          for (const [surveyChainId, detailOptionValue] of Object.entries(
            detailValue
          )) {
            for (const [detailOptionKey, optionValue] of Object.entries(
              detailOptionValue
            )) {
              tableRecord[
                `chainValue_${detailOptionKey}_${surveyChainId}`
              ] = optionValue;
            }
          }

          this.tableData.push(tableRecord);
        }
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        }
      }
    },
    onChangePuncValues(record, editColKey) {
      let detailOptionId = record.detailId;
      let changedValue = record[editColKey];
      const [keyPrefix, YNKey, surveyChainId] = editColKey.split("_");

      const assignTypeValue = this.editedQuestion.perfectStoreDetails
        .assignType;
      if (this.perfectStoreDetails.values[assignTypeValue]) {
        this.perfectStoreDetails.values[assignTypeValue][detailOptionId][
          surveyChainId
        ][YNKey] = parseFloat(changedValue);
      }
    },
    saveTableData() {
      this.editedQuestion.perfectStoreDetails = JSON.parse(
        JSON.stringify(this.perfectStoreDetails)
      );
      this.saveCurrentPuncDetails(this.perfectStoreDetails);
      this.hideModal();
    },
    async init(
      chainList,
      materials,
      products,
      componentOptions,
      detail_value_id_list
    ) {
      this.assignTypeValue = this.editedQuestion?.perfectStoreDetails?.assignType;
      this.showEmpty = true;
      this.chainList = chainList;
      this.materials = materials;
      this.products = products;
      this.componentOptions = componentOptions;
      this.detail_value_id_list = detail_value_id_list;
      this.generate_new_cols();
      this.generate_table_data(this.editedQuestion?.perfectStoreDetails);
      this.perfectStoreDetails = JSON.parse(
        JSON.stringify(this.editedQuestion.perfectStoreDetails)
      );
    },
    async downloadExcelPerfectStoreDetails() {
      let fetch_url = `surveys/downloadexcel_surveyquestion_perfectstoredetails/${this.editedQuestion.id}`;
      const assignTypeValue = this.perfectStoreDetails.assignType;
      let postData = {
        tableData: this.tableData,
        componentOptions: this.componentOptions,
        columns: this.columns,
        assignTypeValue,
        editedQuestion: this.editedQuestion
      };
      const response = await ApiService.post(fetch_url, postData, {
        responseType: "blob"
      });
      download(response, "perfectStoreDetails.xlsx");
    },
    async downloadExcelTemplate() {
      let fetch_url = `surveys/downloadexcel_surveyquestion_perfectstoredetails_template/${this.editedQuestion.id}`;
      const assignTypeValue = this.perfectStoreDetails.assignType;
      let postData = {
        tableData: this.tableData,
        componentOptions: this.componentOptions,
        columns: this.columns,
        assignTypeValue,
        editedQuestion: this.editedQuestion
      };
      const response = await ApiService.post(fetch_url, postData, {
        responseType: "blob"
      });
      download(response, "perfectStoreDetailsTemplate.xlsx");
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader_perfect_store_details.click();
    },
    async onFileChangedUploadingExcel(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        data.append(
          "perfectStoreDetails",
          JSON.stringify(this.perfectStoreDetails)
        );
        data.append("surveyId", JSON.stringify(this.editedSurvey.id));
        data.append("componentId", JSON.stringify(this.editedComponent.id));
        data.append(
          "componentAssignType",
          JSON.stringify(this.editedComponent.assignType)
        );
        data.append("componentOptions", JSON.stringify(this.componentOptions));
        data.append("surveyQuestionType", this.surveyQuestionType);
        if (this.editedQuestion.options?.values) {
          data.append(
            "options",
            JSON.stringify(this.editedQuestion.options.values)
          );
          data.append("isRange", false);
        } else if (this.editedQuestion.options?.ranges) {
          data.append(
            "options",
            JSON.stringify(this.editedQuestion.options.ranges)
          );
          data.append("isRange", true);
        }
        data.append("columns", JSON.stringify(this.columns));
        data.append("surveyQuestionId", this.editedQuestion.id);
        data.append("assignType", this.editedComponent.assignType);
        data.append("editedQuestion", JSON.stringify(this.editedQuestion));
        this.$refs.uploader_perfect_store_details.value = "";
        try {
          const response = await ApiService.post(
            `surveys/uploadexcelForPerfectstoredetails/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            invalidFile,
            message,
            generatedPerfectStoreDetails
          } = response;
          this.snackbar_class = "deep-purple";
          if (success) {
            // this.text_snackbar = `Importing has done successfully.`;
            // this.snackbar = true;
            this.perfectStoreDetails = generatedPerfectStoreDetails;
            this.generate_table_data(this.perfectStoreDetails);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
        } catch (error) {
          console.log(error);
          this.isSelecting = false;
        }
      }
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style>
.punctuationTableWrapper {
  overflow-x: scroll;
}
.punctuationTableWrapper table th,
.punctuationTableWrapper table td {
  padding: 3px;
}
.punctuationTableWrapper table th {
  background: #203864;
  color: #fff;
}
.punctuationTableWrapper table th,
.punctuationTableWrapper table td .inputValue {
  min-width: 70px;
  text-align: center;
  padding: 5px;
}
.punctuationTableWrapper table td {
  position: relative;
  width: 80px;
}
.punctuationTableWrapper table td .inputValue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #777;
}
</style>

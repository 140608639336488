<template>
  <fieldset class="m-0 p-2">
    <legend>
      <b-badge class="legend-title">Perfect store punctuation</b-badge>
    </legend>
    <template
      v-if="
        ![
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
        ].includes(question.survey_question_type.type)
      "
    >
      <div class="d-flex justify-content-center mt-2">
        <b-button variant="outline-success" @click="onClickAddRange">
          <b-icon icon="plus"></b-icon> Add range
        </b-button>
      </div>
      <template
        v-if="
          [
            QUESTION_TYPES.DATE,
            QUESTION_TYPES.MONEY,
            QUESTION_TYPES.DECIMAL,
            QUESTION_TYPES.NUMBER,
          ].includes(question.survey_question_type.type) && puncQueDetail
        "
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            class="d-flex align-center justify-content-center"
          >
            <v-radio-group
              v-model="puncQueDetail.rangeCriteria.type"
              row
              hide-details="auto"
            >
              <v-radio label="Absolute" value="absolute"></v-radio>
              <v-radio label="Increase" value="increase"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="puncQueDetail.rangeCriteria.type === 'increase'">
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="puncIncreaseTypesSelect"
              item-text="label"
              item-value="value"
              v-model="puncQueDetail.rangeCriteria.increaseCriteria.type"
              label="IncreaseType"
              filled
              dense
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="puncVisitTypesSelect"
              item-text="label"
              item-value="value"
              v-model="puncQueDetail.rangeCriteria.increaseCriteria.visitOption"
              label="VisitType"
              filled
              dense
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <div class="punctuationTableWrapper">
              <table border="1" class="text-center" style="width: 100%">
                <thead>
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(rangeItem, rangeKey) in puncQueDetail.ranges"
                  >
                    <tr :key="`range_row_${rangeKey}`">
                      <td>
                        <input
                          class="inputValue"
                          type="number"
                          v-model="rangeItem['from']"
                        />
                      </td>
                      <td>
                        <input
                          class="inputValue"
                          type="number"
                          v-model="rangeItem['to']"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="onClickRemoveRange(rangeItem, rangeKey)"
                        >
                          <b-icon icon="trash"></b-icon>
                        </b-button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </template>
    </template>
    <div
      class="d-flex flex-column mt-2"
      v-if="editableOptions && editableOptions.length > 0"
    >
      <div class="punctuationTableWrapper">
        <table border="1" class="text-center">
          <thead>
            <tr>
              <th>Product/Material</th>
              <template v-for="(optionItem, key) in editableOptions">
                <template
                  v-if="
                    [
                      QUESTION_TYPES.DATE,
                      QUESTION_TYPES.MONEY,
                      QUESTION_TYPES.DECIMAL,
                      QUESTION_TYPES.NUMBER,
                    ].includes(question.survey_question_type.type)
                  "
                >
                  <th :key="`punc_th_${key}`">
                    {{
                      `${formatStr(
                        puncQueDetail.ranges[optionItem]["from"]
                      )} - ${formatStr(puncQueDetail.ranges[optionItem]["to"])}`
                    }}
                  </th>
                </template>
                <template v-else>
                  <th :key="`punc_th_${key}`">{{ optionItem }}</th>
                </template>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, rowkey) in renderPunctuationTableData">
              <tr :key="`punc_tr_${rowkey}`">
                <td>{{ row.pro_mat_name }}</td>
                <template v-for="(optionItem, optkey) in editableOptions">
                  <td :key="`punc_tr_td_${optkey}`">
                    <input
                      class="inputValue"
                      type="number"
                      v-model="row[optionItem]"
                      @change="onChangePuncValues(row, rowkey, optionItem)"
                    />
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </fieldset>
</template>

<script>
import conditionalBuilder from "./conditionalBuilder";
import optionsBuilder from "./optionsBuilder";
import { QUESTION_TYPES } from "../models";
import _ from "lodash";

export default {
  props: [
    "question",
    "components",
    "puncIncreaseTypes",
    "puncVisitTypes",
    "updatePuncValues",
  ],

  components: {
    conditionalBuilder,
    optionsBuilder,
  },

  data() {
    return {
      QUESTION_TYPES,
      puncIncreaseTypesSelect: [],
      puncVisitTypesSelect: [],
      puncQueDetail: null,
    };
  },

  computed: {
    questionProducts() {
      if (this.currentEditedComponent) {
        return this.currentEditedComponent.products;
      }
      return [];
    },
    questionMaterials() {
      if (this.currentEditedComponent) {
        return this.currentEditedComponent.materials;
      }
      return [];
    },
    currentEditedComponent() {
      let filteredComponent = this.components.filter((el) => {
        let filtered_question = el.survey_questions.filter(
          (sel) => sel.uuid === this.question.uuid
        );
        if (filtered_question.length > 0) return true;
      });
      if (filteredComponent.length > 0) {
        return filteredComponent[0];
      }
      return null;
    },
    editableOptions() {
      if (
        [
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
        ].includes(this.question.survey_question_type.type)
      ) {
        let options = [];
        if (this.question.options && this.question.options.values) {
          this.question.options.values.map((el) => {
            if (typeof el === "number") {
              options.push(el.toString());
            } else {
              options.push(el);
            }
          });
        }
        return options;
      } else if (
        [QUESTION_TYPES.BOOL].includes(this.question.survey_question_type.type)
      ) {
        let options = [];
        if (this.question.options && this.question.options.values) {
          for (const [key, value] of Object.entries(
            this.question.options.values
          )) {
            options.push(value);
          }
        }
        return options;
      } else if (
        [
          QUESTION_TYPES.DATE,
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER,
        ].includes(this.question.survey_question_type.type)
      ) {
        let options = [];
        if (this.puncQueDetail && this.puncQueDetail.ranges) {
          this.puncQueDetail.ranges.map((el, index) => {
            // options.push(`${el.from} - ${el.to}`);
            options.push(index);
          });
        }
        return options;
      }
      return null;
    },
    renderPunctuationTableData() {
      let data = [];
      if (this.editableOptions && this.editableOptions.length > 0) {
        if (
          this.questionProducts.length > 0 ||
          this.questionMaterials.length > 0
        ) {
          this.questionProducts.map((el) => {
            let criteria = {
              dataId: el.id,
              category: 1,
            };
            if (!data.some((el) => _.isEqual(el.criteria, criteria))) {
              data.push({
                criteria,
                pro_mat_name: el.name,
              });
            }
          });
          this.questionMaterials.map((el) => {
            let criteria = {
              dataId: el.id,
              category: 2,
            };
            if (!data.some((el) => _.isEqual(el.criteria, criteria))) {
              data.push({
                criteria,
                pro_mat_name: el.name,
              });
            }
          });
        } else {
          let criteria = {
            dataId: -1,
            category: 0,
          };
          if (!data.some((el) => _.isEqual(el.criteria, criteria))) {
            data.push({
              criteria,
              pro_mat_name: "-",
            });
          }
        }
        data = data.map((el) => {
          let updateItem = { ...el };
          this.editableOptions.map((opt) => {
            if (!this.question.puncQueDetail.values[opt]) {
              this.question.puncQueDetail.values[opt] = [];
            }
            let filteredItem = this.question.puncQueDetail.values[opt].filter(
              (valItem) =>
                valItem.dataId === updateItem.criteria.dataId &&
                valItem.category === updateItem.criteria.category
            );
            if (filteredItem.length > 0) {
              filteredItem = filteredItem[0];
              updateItem[opt] = filteredItem.value;
            } else {
              updateItem[opt] = 0;
            }
          });
          return updateItem;
        });
      }
      return data;
    },
  },

  methods: {
    formatStr(value) {
      return value ? value : "";
    },
    onChangePuncValues(row, rowkey, optionItem) {
      let filteredIndex = this.question.puncQueDetail.values[
        optionItem
      ].findIndex(
        (el) =>
          el.dataId === row.criteria.dataId &&
          el.category === row.criteria.category
      );
      if (filteredIndex >= 0) {
        this.question.puncQueDetail.values[optionItem][
          filteredIndex
        ].value = parseFloat(row[optionItem]);
      }
      this.updatePuncValues();
    },
    convertObjectArray(data) {
      let new_value = [];
      for (const [key, value] of Object.entries(data)) {
        let push_item = {
          value: key,
          label: value,
        };
        new_value.push(push_item);
      }
      return new_value;
    },
    checkExistValuesInDetail(optEl) {
      let new_value = [];
      let updated_pro_ids = this.questionProducts.map((el) => el.id);
      let updated_mat_ids = this.questionMaterials.map((el) => el.id);
      this.question.puncQueDetail.values[optEl].map((el) => {
        if (
          (updated_pro_ids.includes(el.dataId) && el.category === 1) ||
          (updated_mat_ids.includes(el.dataId) && el.category === 2)
        ) {
          new_value.push(el);
        }
      });
      return new_value;
    },
    onClickAddRange() {
      let new_push_row = {
        from: null,
        to: null,
      };
      let isVaild = true;
      this.puncQueDetail.ranges.map((el) => {
        if (!el.from || !el.to) {
          isVaild = false;
        }
      });
      if (isVaild) {
        this.puncQueDetail.ranges.push(new_push_row);
      }
    },
    onClickRemoveRange(rangeItem, rangeKey) {
      this.puncQueDetail.ranges.splice(rangeKey, 1);
    },
    initpuncQueDetail() {
      if (
        [
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
        ].includes(this.question.survey_question_type.type)
      ) {
        this.question.puncQueDetail.options = this.editableOptions;
        if (
          this.question.puncQueDetail.options &&
          (this.questionProducts.length > 0 ||
            this.questionMaterials.length > 0)
        ) {
          this.question.puncQueDetail.options.map((optEl) => {
            if (!this.question.puncQueDetail.values[optEl]) {
              this.question.puncQueDetail.values[optEl] = [];
            }
            this.questionProducts.map((proItem) => {
              let push_item = {
                dataId: proItem.id,
                category: 1,
                value: 0,
              };
              let filterIndex = this.question.puncQueDetail.values[
                optEl
              ].findIndex(
                (el) => el.dataId === proItem.id && el.category === 1
              );
              if (filterIndex < 0) {
                this.question.puncQueDetail.values[optEl].push(push_item);
              }
            });
            this.questionMaterials.map((matItem) => {
              let push_item = {
                dataId: matItem.id,
                category: 2,
                value: 0,
              };
              let filterIndex = this.question.puncQueDetail.values[
                optEl
              ].findIndex(
                (el) => el.dataId === matItem.id && el.category === 2
              );
              if (filterIndex < 0) {
                this.question.puncQueDetail.values[optEl].push(push_item);
              }
            });
            this.question.puncQueDetail.values[
              optEl
            ] = this.checkExistValuesInDetail(optEl);
          });
          let alreadyOptionkeys = Object.keys(
            this.question.puncQueDetail.values
          );
          alreadyOptionkeys.map((puncQueDetailValueOpt) => {
            if (!this.editableOptions.includes(puncQueDetailValueOpt)) {
              this.question.puncQueDetail.values = _.omit(
                this.question.puncQueDetail.values,
                puncQueDetailValueOpt
              );
            }
          });
        } else if (this.question.puncQueDetail.options) {
          this.question.puncQueDetail.options.map((optEl) => {
            if (!this.question.puncQueDetail.values[optEl]) {
              this.question.puncQueDetail.values[optEl] = [];
            }
            let push_item = {
              dataId: -1,
              category: 0, // not selectbox-list
              value: 0,
            };
            let filterIndex = this.question.puncQueDetail.values[
              optEl
            ].findIndex((el) => el.dataId === -1 && el.category === 0);
            if (filterIndex < 0) {
              this.question.puncQueDetail.values[optEl].push(push_item);
            }
          });
        }
      }
    },
    init() {
      this.puncIncreaseTypesSelect = this.convertObjectArray(
        this.puncIncreaseTypes
      );
      this.puncVisitTypesSelect = this.convertObjectArray(this.puncVisitTypes);
      if (!this.question.puncQueDetail) {
        this.question.puncQueDetail = {
          options: [],
          rangeCriteria: {
            type: "absolute",
            increaseCriteria: {
              type: null,
              visitOption: null,
            },
          },
          ranges: [],
          values: {},
          puncQueValue: 0,
        };
        this.question.puncQueValue = 0;
      }
      this.initpuncQueDetail();
      this.puncQueDetail = this.question.puncQueDetail;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.punctuationTableWrapper {
  overflow-x: scroll;
}
.punctuationTableWrapper table th,
.punctuationTableWrapper table td {
  padding: 3px;
}
.punctuationTableWrapper table th {
  background: #203864;
  color: #fff;
}
.punctuationTableWrapper table th,
.punctuationTableWrapper table td .inputValue {
  min-width: 30px;
  text-align: center;
  padding: 5px;
}
</style>

<template>
  <div class="d-flex flex-column">
    <div class="d-flex" style="display: none !important;">
      <label class="mr-1">id:</label>
      <b-badge
        v-if="!question.id"
        variant="danger"
        class="mt-1 mx-1"
        style="height: 16px;"
        >N/A</b-badge
      >
      {{ question.id }}
    </div>

    <div class="d-flex">
      <label class="mr-1">uuid:</label>{{ question.uuid }}
    </div>

    <div class="d-flex mt-3">
      <v-text-field
        v-model="question.name"
        v-bind:label="$t('brands.name')"
        variant="outlined"
        dense
        hide-details="auto"
      ></v-text-field>
    </div>

    <div class="d-flex mt-3">
      <v-checkbox
        dense
        v-model="question.hideLabel"
        label="ocultar nombre"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mt-3">
      <imageUploader
        :imageCategory="`QUESTION`"
        :data="question"
        @onChangePhotoId="
          (photoId, imagePreviewUrl) =>
            onChangePhotoId(photoId, imagePreviewUrl)
        "
        @removePhotoId="removePhotoId"
      />
    </div>

    <div class="d-flex">
      <v-checkbox
        dense
        v-model="question.hide"
        label="desactivar?"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isRemember"
        label="Recordar"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex flex-column datepicker">
        <label class="m-0">Desde:</label>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }"
          size="sm"
          locale="es"
          v-model="question.activeDateFrom"
          class="mb-2"
        >
        </b-form-datepicker>
      </div>

      <div class="d-flex flex-column datepicker">
        <label class="m-0">Hasta:</label>
        <b-form-datepicker
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          }"
          size="sm"
          locale="es"
          v-model="question.activeDateTo"
          class="mb-2"
        >
        </b-form-datepicker>
      </div>
    </div>

    <div class="d-flex mb-0">
      <v-checkbox
        dense
        v-model="question.repeats"
        label="se repite?"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <template v-if="question.repeats">
      <div class="d-flex align-center mt-3 px-3">
        <v-text-field
          class="mr-1"
          type="number"
          v-model="question.repeatsValue"
          v-bind:label="$t('Repeat Number')"
          dense
          hide-details="auto"
        ></v-text-field>
      </div>
      <div class="d-flex align-center mt-2 px-3">
        <v-autocomplete
          v-model="question.repeatsUnit"
          v-bind:label="$t('Repeat Frequency')"
          :items="repeatsUnitValues"
          item-text="label"
          item-value="value"
          filled
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
    </template>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isMandatory"
        :label="`${this.$t('Is Mandatory')}`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isKPI"
        :label="`KPI`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isAlwaysEditing"
        :label="`Editar siempre`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        v-if="question.isKPI"
        dense
        v-model="question.isSales"
        :label="`Es Venta`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isPortalPhotos"
        :label="`Portal Fotos`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-autocomplete
        v-if="question.isPortalPhotos"
        v-model="question.idPortalPhotoSurveyQuestions"
        label="Fotos for portal"
        :items="this.selectablePhotoQuestions"
        item-text="label"
        item-value="uuid"
        filled
        dense
        multiple
        hide-details="auto"
      ></v-autocomplete>
    </div>

    <div class="d-flex mb-1 mt-0">
      <v-checkbox
        dense
        v-model="question.isCDM"
        :label="`CDM`"
        hide-details="auto"
      ></v-checkbox>
    </div>

    <div class="d-flex mb-3">
      <v-autocomplete
        v-model="question.presetValue"
        label="preset"
        :items="PRESET_VALUE_TYPES_VALUES"
        item-text="label"
        item-value="value"
        filled
        dense
        hide-details="auto"
      ></v-autocomplete>
    </div>

    <options-builder
      :key="'ob_' + question.uuid"
      v-model="question"
      :question="question"
      @inputQuestion="result => persistQuestionValue(result)"
      ref="refOptionsBuilder"
    ></options-builder>

    <div class="d-flex mt-3 mb-3">
      <fieldset class="m-0 p-2" style="width: 100%">
        <legend>
          <b-badge class="legend-title">Perfect store punctuation</b-badge>
        </legend>
        <div class="d-flex justify-content-center mt-2 mb-2">
          <v-radio-group row v-model="question.isSumPS">
            <v-radio :value="true" :label="$t('Yes')"></v-radio>
            <v-radio :value="false" :label="$t('No')"></v-radio>
          </v-radio-group>
        </div>
        <div class="d-flex justify-content-center mt-2 mb-2">
          <b-button variant="success" @click="onClickPunctuationDetail">
            Punctuation Details
          </b-button>
        </div>
      </fieldset>
    </div>

    <div class="d-flex mt-3 mb-3">
      <fieldset class="m-0 p-2" style="width: 100%">
        <legend>
          <b-badge class="legend-title">{{ $t("objective") }} POS</b-badge>
        </legend>
        <div class="d-flex justify-content-center mt-2 mb-2">
          <b-button variant="success" @click="onClickObjectivePosDetail">
            {{ $t("objective") }} Details
          </b-button>
        </div>
      </fieldset>
    </div>

    <fieldset
      class="m-0 mt-1 p-2 d-flex align-center justify-content-center"
      v-if="
        [QUESTION_TYPES.PHOTO].includes(question?.survey_question_type.type)
      "
    >
      <v-radio-group row v-model="question.photoType">
        <template v-for="(typeItem, key) in questionPhotoTypes">
          <v-radio
            :key="`question_photo_type_${key}`"
            :value="key"
            :label="typeItem"
          ></v-radio>
        </template>
      </v-radio-group>
    </fieldset>

    <DialogPerfectStoreDetail
      ref="dialogPerfectStoreDetail"
      :editedSurvey="editedSurvey"
      :editedComponent="editedComponent"
      :editedQuestion="question"
      :saveCurrentPuncDetails="saveCurrentPuncDetails"
    />

    <DialogQuestionPosObjectives
      ref="dialogQuestionPosObjectives"
      :editedSurvey="editedSurvey"
      :editedComponent="editedComponent"
      :editedQuestion="question"
    />

    <conditionalBuilderV2
      :key="'cbv2_' + question.uuid"
      :components="components"
      class="mt-2"
      :question="question"
    ></conditionalBuilderV2>

    <fieldset class="m-0 mt-1 p-2 d-flex align-center justify-content-center">
      <v-radio-group
        v-model="question.questionImportType"
        @change="onChangeQuestionImportType"
      >
        <template v-for="(typeItem, key) in questionImportTypes">
          <v-radio
            :key="`question_import_type_${key}`"
            :value="key"
            :label="typeItem"
          ></v-radio>
        </template>
      </v-radio-group>
    </fieldset>

    <calculationBuilder
      :key="'calculation_builder_' + question.uuid"
      :components="components"
      :question="question"
      :checkValidationCondItem="checkValidationCondItem"
      :checkForParenthesys="checkForParenthesys"
      class="mt-2"
      v-if="question.questionImportType === 'CALCULATION_DATA'"
    />

    <div class="d-flex justify-content-end">
      <b-button
        @click="remove"
        size="sm"
        style="width: 60px"
        class="mt-2 btn-small"
        variant="outline-danger"
        >eliminar</b-button
      >
    </div>
  </div>
</template>

<script>
// import conditionalBuilder from "./conditionalBuilder";
import conditionalBuilderV2 from "./conditionalBuilderV2";
import calculationBuilder from "./calculationBuilder";
import optionsBuilder from "./optionsBuilder";
import punctuationDetail from "./punctuationDetail";
import punctuationDetailForRange from "./punctuationDetailForRange";
import { QUESTION_TYPES } from "../models";
import ApiService from "@/services/apiService";
import { ellipsis, logWarning, logInfo, logError } from "@/utils";
import DialogPerfectStoreDetail from "./DialogPerfectStoreDetail.vue";
import DialogQuestionPosObjectives from "./DialogQuestionPosObjectives.vue";
import imageUploader from "./imageUploader";

const PRESET_VALUE_TYPES = {
  new: "sin preset", // does not preset
  once: "ejecución unica", //pregunta de ejecucion unica
  previous: "valor últ. sesión", //presets with las session value
  info: "últ. sesión solo info" // display previous value as info
};
const PRESET_VALUE_TYPES_VALUES = [
  { label: "sin preset", value: "new" },
  { label: "ejecución unica", value: "once" },
  { label: "valor últ. sesión", value: "previous" },
  { label: "últ. sesión solo info", value: "info" }
];

export default {
  props: [
    "question",
    "components",
    "editedComponent",
    "editedSurvey",
    "repeatsUnitValues",
    "puncIncreaseTypes",
    "puncVisitTypes",
    "questionPhotoTypes",
    "questionImportTypes",
    "checkForParenthesys",
    "checkValidationCondItem"
  ],

  components: {
    // conditionalBuilder,
    conditionalBuilderV2,
    calculationBuilder,
    optionsBuilder,
    punctuationDetail,
    punctuationDetailForRange,
    DialogPerfectStoreDetail,
    DialogQuestionPosObjectives,
    imageUploader
  },

  data() {
    return {
      PRESET_VALUE_TYPES,
      PRESET_VALUE_TYPES_VALUES,
      QUESTION_TYPES
    };
  },

  computed: {
    selectablePhotoQuestions() {
      let ret = [];
      if (this.editedSurvey && this.editedSurvey?.survey_components) {
        this.editedSurvey.survey_components.map(comEl => {
          if (comEl && comEl?.survey_questions) {
            comEl.survey_questions.map(queEl => {
              const { survey_question_type } = queEl;
              if (
                survey_question_type &&
                survey_question_type?.type &&
                survey_question_type?.type == QUESTION_TYPES.PHOTO
              ) {
                let push_item = { ...queEl };
                push_item.label = `${comEl.name} / ${push_item.name}`;
                ret.push(push_item);
              }
            });
          }
        });
      }
      return ret;
    }
  },

  methods: {
    onChangePhotoId(photoId, imagePreviewUrl) {
      this.question.photoId = photoId;
      this.question.imagePreviewUrl = imagePreviewUrl;
    },
    removePhotoId() {
      this.question.photoId = null;
      this.question.imagePreviewUrl = null;
      this.question.surveyQuestionPhoto = null;
    },
    persistQuestionValue(result) {
      this.$emit("update", result);
    },
    remove() {
      this.$emit("remove");
    },
    nameWithLang({ name, language }) {
      return `${name} — [${language}]`;
    },
    calcPuncQueValue(questionItem) {
      let totalSum = 0;
      if (questionItem?.puncQueDetail?.values) {
        for (const [dataKey, valueList] of Object.entries(
          questionItem.puncQueDetail.values
        )) {
          for (const valueItem of valueList) {
            if (valueItem?.value) {
              totalSum += valueItem.value;
            }
          }
        }
      }
      return totalSum;
    },
    updatePuncValues() {
      if (this.components) {
        this.components.map(comEl => {
          if (!comEl.puncComValue) {
            comEl.puncComValue = 0;
          } else {
            comEl.puncComValue = parseFloat(comEl.puncComValue);
          }
          let sumPuncComValue = 0;
          if (comEl.survey_questions) {
            comEl.survey_questions.map(queEl => {
              if (!queEl.puncQueValue) {
                queEl.puncQueValue = 0;
              } else {
                queEl.puncQueValue = parseFloat(queEl.puncQueValue);
              }
              queEl.puncQueValue = this.calcPuncQueValue(queEl);
              sumPuncComValue += queEl.puncQueValue;
              return queEl;
            });
          }
          comEl.puncComValue = sumPuncComValue;
          return comEl;
        });
      }
    },
    calcPerfectStoreQueValue(questionItem, assignTypeValue) {
      let totalSum = 0;
      // const assignTypeValue = questionItem?.perfectStoreDetails?.assignType;
      if (
        questionItem?.perfectStoreDetails?.values &&
        questionItem.perfectStoreDetails.values[assignTypeValue]
      ) {
        for (const [proMatOptionId, proMatOptionDetails] of Object.entries(
          questionItem.perfectStoreDetails.values[assignTypeValue]
        )) {
          for (const [surveyChainId, valueDetail] of Object.entries(
            proMatOptionDetails
          )) {
            for (const [YNKey, value] of Object.entries(valueDetail)) {
              if (value) {
                totalSum += value;
              }
            }
          }
        }
      }
      return totalSum;
    },
    updatePerfectStoreValues() {
      if (this.components) {
        this.components.map(comEl => {
          let assignTypeValue = comEl.assignType;
          if (!comEl.puncComValue) {
            comEl.puncComValue = 0;
          } else {
            comEl.puncComValue = parseFloat(comEl.puncComValue);
          }
          let sumPuncComValue = 0;
          if (comEl.survey_questions) {
            comEl.survey_questions = comEl.survey_questions.map(queEl => {
              if (!queEl.puncQueValue) {
                queEl.puncQueValue = 0;
              } else {
                queEl.puncQueValue = parseFloat(queEl.puncQueValue);
              }
              queEl.puncQueValue = this.calcPerfectStoreQueValue(
                queEl,
                assignTypeValue
              );
              sumPuncComValue += queEl.puncQueValue;
              return queEl;
            });
          }
          comEl.puncComValue = sumPuncComValue;
          return comEl;
        });
      }
    },
    saveCurrentPuncDetails(puncDetails) {
      this.question.perfectStoreDetails = puncDetails;
      this.updatePerfectStoreValues();
    },
    checkRowOptionIntheChain(
      chainId,
      subChainId,
      sub2ChainId,
      pro_mat_option_id,
      assignTypeValue,
      currentQuestion,
      products,
      materials
    ) {
      let filterTargetList = null;
      if (assignTypeValue === "PRODUCT") {
        filterTargetList = products;
      } else if (assignTypeValue === "MATERIAL") {
        filterTargetList = materials;
      } else {
        return true;
      }
      let foundTargets = filterTargetList.filter(el => {
        let foundChain = el.chainData.filter(
          chainEl =>
            el.id === pro_mat_option_id &&
            chainEl.chainId === chainId &&
            chainEl.subChainId === subChainId &&
            chainEl.sub2ChainId === sub2ChainId
        );
        if (foundChain.length > 0) return true;
        return false;
      });
      if (foundTargets.length > 0) {
        return true;
      }
      return false;
    },
    async onClickPunctuationDetail() {
      if (!this.$refs.refOptionsBuilder.isValid) {
        logError("Por favor revise la pregunta de opciones.");
        return;
      }
      this.question.perfectStoreDetails = this.question.perfectStoreDetails || {
        puncQueValue: 0,
        values: {}
      };
      const assignTypeValue = this.editedComponent.assignType;
      this.question.perfectStoreDetails.assignType = assignTypeValue;
      if (!this.question.perfectStoreDetails.values) {
        this.question.perfectStoreDetails.values = {};
        this.question.perfectStoreDetails.values[assignTypeValue] = {};
      } else if (!this.question.perfectStoreDetails.values[assignTypeValue]) {
        this.question.perfectStoreDetails.values[assignTypeValue] = {};
      }
      if (this.editedSurvey && this.editedComponent) {
        let getCriteriaOfPuncDetails = {
          surveyId: this.editedSurvey.id,
          componentAssignType: this.editedComponent.assignType
        };
        const {
          chainList,
          materials,
          products
          // componentOptions,
        } = await ApiService.post(
          `surveys/getInitialCriteriaOfPuncDetails`,
          getCriteriaOfPuncDetails
        );
        let componentOptions = [];
        if (this.editedComponent?.component_options) {
          componentOptions = Object.values(
            this.editedComponent?.component_options?.values
          );
        }
        this.surveyChainList = chainList;
        this.surveyMaterials = materials;
        this.surveyProducts = products;
        let detail_value_id_list = [];
        if (chainList.length === 0) {
          logError("You can't edit it. You should assign chains for survey !");
          return;
        } else {
          if (assignTypeValue === "PRODUCT") {
            detail_value_id_list = products.map(el => el.id);
          } else if (assignTypeValue === "MATERIAL") {
            detail_value_id_list = materials.map(el => el.id);
          } else {
            if (componentOptions.length > 0) {
              detail_value_id_list = componentOptions.map(el => el.optionValue);
            } else {
              if (this.question.options?.values) {
                detail_value_id_list = Object.keys(
                  this.question.options?.values
                );
              } else if (this.question.options?.ranges) {
                detail_value_id_list = Object.keys(
                  this.question.options?.ranges
                );
              }
            }
          }
          let new_detail_values_for_assigntype = {};
          if (detail_value_id_list.length === 0) {
            detail_value_id_list.push("default");
          }
          if (detail_value_id_list.length > 0) {
            detail_value_id_list.map(pro_mat_option_id => {
              if (
                this.question.perfectStoreDetails.values[assignTypeValue][
                  pro_mat_option_id
                ]
              ) {
                new_detail_values_for_assigntype[
                  pro_mat_option_id
                ] = this.question.perfectStoreDetails.values[assignTypeValue][
                  pro_mat_option_id
                ];
              } else {
                new_detail_values_for_assigntype[pro_mat_option_id] = {};
              }
              let newChainList = [{ id: "default" }, ...chainList];
              newChainList.map(chainItem => {
                let surveyChainId = chainItem.id;
                const { chainId, subChainId, sub2ChainId } = chainItem;
                let isExistChainKey = false;
                if (
                  this.question.perfectStoreDetails.values[assignTypeValue][
                    pro_mat_option_id
                  ]
                ) {
                  if (surveyChainId === "default") {
                    isExistChainKey = Object.keys(
                      this.question.perfectStoreDetails.values[assignTypeValue][
                        pro_mat_option_id
                      ]
                    )
                      .map(el => el)
                      .includes(surveyChainId);
                  } else {
                    isExistChainKey = Object.keys(
                      this.question.perfectStoreDetails.values[assignTypeValue][
                        pro_mat_option_id
                      ]
                    )
                      .map(el => parseInt(el))
                      .includes(surveyChainId);
                  }
                }
                let isRowOptionIntheChain =
                  surveyChainId === "default"
                    ? true
                    : this.checkRowOptionIntheChain(
                        chainId,
                        subChainId,
                        sub2ChainId,
                        pro_mat_option_id,
                        assignTypeValue,
                        this.question,
                        products,
                        materials
                      );
                if (isExistChainKey && isRowOptionIntheChain) {
                  new_detail_values_for_assigntype[pro_mat_option_id][
                    surveyChainId
                  ] = this.question.perfectStoreDetails.values[assignTypeValue][
                    pro_mat_option_id
                  ][surveyChainId];
                } else {
                  new_detail_values_for_assigntype[pro_mat_option_id][
                    surveyChainId
                  ] = {};
                }
                if (
                  (assignTypeValue !== "OTHERS" ||
                    (assignTypeValue === "OTHERS" &&
                      componentOptions.length > 0)) &&
                  ![
                    QUESTION_TYPES.LONGTEXT,
                    QUESTION_TYPES.STRING,
                    QUESTION_TYPES.DATE
                  ].includes(this.question.survey_question_type.type) &&
                  isRowOptionIntheChain
                ) {
                  if (this.question.options?.values) {
                    for (const questionOptionId of Object.keys(
                      this.question.options?.values
                    )) {
                      if (
                        Object.keys(
                          new_detail_values_for_assigntype[pro_mat_option_id][
                            surveyChainId
                          ]
                        ).includes(questionOptionId)
                      ) {
                        new_detail_values_for_assigntype[pro_mat_option_id][
                          surveyChainId
                        ][
                          questionOptionId
                        ] = this.question.perfectStoreDetails.values[
                          assignTypeValue
                        ][pro_mat_option_id][surveyChainId][questionOptionId];
                      } else {
                        new_detail_values_for_assigntype[pro_mat_option_id][
                          surveyChainId
                        ][questionOptionId] = null;
                      }
                    }
                  } else if (this.question.options?.ranges) {
                    for (const questionOptionId of Object.keys(
                      this.question.options.ranges
                    )) {
                      if (
                        Object.keys(
                          new_detail_values_for_assigntype[pro_mat_option_id][
                            surveyChainId
                          ]
                        ).includes(questionOptionId)
                      ) {
                        new_detail_values_for_assigntype[pro_mat_option_id][
                          surveyChainId
                        ][
                          questionOptionId
                        ] = this.question.perfectStoreDetails.values[
                          assignTypeValue
                        ][pro_mat_option_id][surveyChainId][questionOptionId];
                      } else {
                        new_detail_values_for_assigntype[pro_mat_option_id][
                          surveyChainId
                        ][questionOptionId] = null;
                      }
                    }
                  }
                } else if (isRowOptionIntheChain) {
                  if (
                    !Object.keys(
                      new_detail_values_for_assigntype[pro_mat_option_id][
                        surveyChainId
                      ]
                    ).includes("otherValue")
                  ) {
                    new_detail_values_for_assigntype[pro_mat_option_id][
                      surveyChainId
                    ]["otherValue"] = null;
                  }
                }
                return chainItem;
              });
              return pro_mat_option_id;
            });
          }
          this.question.perfectStoreDetails.values[
            assignTypeValue
          ] = new_detail_values_for_assigntype;
        }
        this.$refs.dialogPerfectStoreDetail.showModal(
          chainList,
          materials,
          products,
          componentOptions,
          detail_value_id_list
        );
      }
    },
    async onClickObjectivePosDetail() {
      if (this.question?.id) {
        this.$refs.dialogQuestionPosObjectives.showModal(this.question);
      } else {
        logError(
          "You can edit them after saving this survey, please save this survey at first."
        );
      }
    },
    onChangeQuestionImportType() {
      this.question.calculations = null;
      this.question.calcParenthesys = null;
    }
  },
  mounted() {
    // this.updatePuncValues();
    this.updatePerfectStoreValues();
  }
};
</script>

<style></style>

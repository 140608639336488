var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"m-0 p-2"},[_c('legend',[_c('b-badge',{staticClass:"legend-title"},[_vm._v("aparece si")])],1),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('v-autocomplete',{attrs:{"label":"componente","items":_vm.components,"item-text":"name","item-value":"uuid","filled":"","dense":"","hide-details":"auto","clearable":""},on:{"change":_vm.onChangeComponent},model:{value:(_vm.selectedComponentUUId),callback:function ($$v) {_vm.selectedComponentUUId=$$v},expression:"selectedComponentUUId"}})],1),_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('v-autocomplete',{attrs:{"label":"pregunta","items":_vm.xQuestions,"item-text":"name","item-value":"uuid","filled":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.selectedQuestionUUId),callback:function ($$v) {_vm.selectedQuestionUUId=$$v},expression:"selectedQuestionUUId"}})],1),(_vm.isAvailableAddConditions)?_c('div',{staticClass:"d-flex flex-column"},[_vm._l((_vm.bufferConditions),function(conditionItem,key){return [_c('div',{key:`cond_item_${key}`,staticClass:"d-flex justify-content-between align-center mb-3"},[(key !== 0)?_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":['AND', 'OR'],"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto"},model:{value:(conditionItem.integrationOfOperation),callback:function ($$v) {_vm.$set(conditionItem, "integrationOfOperation", $$v)},expression:"conditionItem.integrationOfOperation"}}):_vm._e(),_c('div',{staticStyle:{"max-width":"150px"}},[_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":_vm.selectedOperatorOpt,"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto","clearable":""},model:{value:(conditionItem.selectedOperator),callback:function ($$v) {_vm.$set(conditionItem, "selectedOperator", $$v)},expression:"conditionItem.selectedOperator"}})],1),(
            [
              _vm.QUESTION_TYPES.SELECT,
              _vm.QUESTION_TYPES.BOOL,
              _vm.QUESTION_TYPES.CHECKBOX,
              _vm.QUESTION_TYPES.ACTION,
              _vm.QUESTION_TYPES.RADIO,
            ].includes(_vm.selectedQuestion.survey_question_type.type)
          )?_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":_vm.selectedOperatorValueList,"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto","clearable":""},model:{value:(conditionItem.selectedOperatorValue),callback:function ($$v) {_vm.$set(conditionItem, "selectedOperatorValue", $$v)},expression:"conditionItem.selectedOperatorValue"}})],1):_c('div',{staticClass:"d-flex justify-content-between align-center"},[(
              [_vm.QUESTION_TYPES.DATE].includes(
                _vm.selectedQuestion.survey_question_type.type
              )
            )?[_c('b-form-datepicker',{attrs:{"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"locale":"es"},model:{value:(conditionItem.selectedOperatorValue),callback:function ($$v) {_vm.$set(conditionItem, "selectedOperatorValue", $$v)},expression:"conditionItem.selectedOperatorValue"}})]:[_c('v-text-field',{staticClass:"mr-3",attrs:{"dense":"","hide-details":"auto"},model:{value:(conditionItem.selectedOperatorValue),callback:function ($$v) {_vm.$set(conditionItem, "selectedOperatorValue", $$v)},expression:"conditionItem.selectedOperatorValue"}})]],2)],1),_c('div',{key:`cond_item_new_row_${key}`,staticClass:"d-flex align-center justify-content-end mb-3"},[_c('b-button',{staticClass:"btn-small mr-1 no-radius survey_conditional_andor_btn",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addBufferItem}},[_vm._v(" AND/OR ")]),_c('b-button',{staticClass:"btn-small mr-1 no-radius survey_conditional_andor_btn",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.add(conditionItem, key)}}},[_vm._v(" ADD ")])],1)]})],2):_vm._e(),_vm._l((this.question.conditionals),function(cond,key){return _c('div',{key:`conditions_values_${key}`,staticClass:"border px-1 py-1",staticStyle:{"color":"blueviolet"}},[_c('div',{staticClass:"d-flex justify-content-between align-center"},[_vm._v(" "+_vm._s(_vm.formatCondStr(cond, key))+" "),_c('input',{staticClass:"button-xs mr-1",attrs:{"type":"button","value":"X"},on:{"click":function($event){return _vm.remove(cond)}}})])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
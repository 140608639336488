<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn
          color="success"
          dark
          class="mr-2"
          :loading="isSelecting"
          @click="onUploadClick"
        >
          <b-icon icon="upload" aria-hidden="true"></b-icon>&nbsp;
          {{ buttonText }}
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/jpeg,image/png"
          @change="onFileChanged"
        />
        <v-btn
          color="red"
          dark
          class="mr-2"
          @click="onClickDelete"
          v-if="isRemoveablePhoto"
        >
          <b-icon icon="trash" aria-hidden="true"></b-icon>&nbsp; Delete...
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="!imagePreviewUrl && imageSrcUrl">
      <v-col cols="12" md="12">
        <img class="img_preview" :src="imageSrcUrl" />
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="imagePreviewUrl">
      <v-col cols="12" md="12">
        <img class="img_preview" :src="imagePreviewUrl" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "imageUploader",
  props: ["imageCategory", "data"],
  data: function() {
    return {
      dataCategory: this.imageCategory,
      value: this.data,
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Upload Image",
      imagePreviewUrl: this.data?.imagePreviewUrl,
      uploadedPhotoId: null,
    };
  },
  watch: {},
  computed: {
    imageSrcUrl() {
      let photo = null;
      if (
        this.dataCategory === "COMPONENT" &&
        this.value?.surveyComponentPhoto
      ) {
        photo = this.value?.surveyComponentPhoto;
      } else if (
        this.dataCategory === "QUESTION" &&
        this.value?.surveyQuestionPhoto
      ) {
        photo = this.value?.surveyQuestionPhoto;
      } else if (
        this.dataCategory === "COMPONENT_OPTION" &&
        this.value?.surveyComponentOptionPhoto
      ) {
        photo = this.value?.surveyComponentOptionPhoto;
      }
      if (photo && photo?.file) {
        return ApiService.getImageUri(photo);
      }
      return null;
    },
    isRemoveablePhoto() {
      return this.imagePreviewUrl || this.imageSrcUrl;
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
  },
  methods: {
    onClickDelete() {
      if (this.imagePreviewUrl) this.imagePreviewUrl = null;
      this.$emit("removePhotoId");
    },
    onUploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.isSelecting = true;
      this.selectedFile = e.target.files[0];
      this.imagePreviewUrl = URL.createObjectURL(this.selectedFile);
      let { data } = await ApiService.uploadImage(this.selectedFile);
      if (data) {
        this.uploadedPhotoId = data.id;
        this.$emit(
          "onChangePhotoId",
          this.uploadedPhotoId,
          this.imagePreviewUrl
        );
      }
      this.isSelecting = false;
    },
    async init() {},
  },
  async updated() {},
  async mounted() {
    this.init();
  },
};
</script>

<style>
.img_preview {
  width: 100%;
}
</style>

<template>
  <fieldset class="m-0 mb-3 p-2">
    <legend><b-badge class="legend-title">aparece si</b-badge></legend>

    <template v-for="(conditionItem, key) in bufferConditions">
      <v-row :key="`cond_comp_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="conditionItem.component"
            label="componente"
            :items="components"
            item-text="name"
            item-value="uuid"
            filled
            dense
            hide-details="auto"
            @change="onChangeComponent(conditionItem, key)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`cond_comp_que_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="conditionItem.question"
            label="pregunta"
            :items="xQuestions(conditionItem)"
            item-text="name"
            item-value="uuid"
            filled
            dense
            hide-details="auto"
            @change="onChangeComponentQuestion(conditionItem, key)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        :key="`cond_item_${key}`"
        v-if="isAvailableAddConditions(conditionItem)"
      >
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="conditionItem.op"
            :items="selectedOperatorOpt(conditionItem)"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
            class="mr-3 survey_conditional_select"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <div
            class="d-flex justify-content-between align-center"
            v-if="
              [
                QUESTION_TYPES.SELECT,
                QUESTION_TYPES.BOOL,
                QUESTION_TYPES.CHECKBOX,
                QUESTION_TYPES.ACTION,
                QUESTION_TYPES.RADIO,
              ].includes(xType(conditionItem))
            "
          >
            <v-autocomplete
              v-model="conditionItem.val"
              :items="selectedOperatorValueList(conditionItem)"
              item-text="label"
              item-value="value"
              filled
              dense
              hide-details="auto"
              class="mr-3 survey_conditional_select"
            ></v-autocomplete>
          </div>
          <div class="d-flex justify-content-between align-center" v-else>
            <template
              v-if="[QUESTION_TYPES.DATE].includes(xType(conditionItem))"
            >
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="es"
                v-model="conditionItem.val"
              >
              </b-form-datepicker>
            </template>
            <template v-else>
              <v-text-field
                :class="`mr-3`"
                v-model="conditionItem.val"
                dense
                hide-details="auto"
                :error-messages="
                  conditionItem?.isInvalid ? conditionItem.error_message : ''
                "
              ></v-text-field>
            </template>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="d-flex align-center justify-content-end mb-3">
            <b-button
              @click="addBufferItem"
              size="sm"
              class="btn-small mr-1 no-radius survey_conditional_andor_btn"
              variant="outline-secondary"
            >
              + AND/OR
            </b-button>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="
            bufferConditions.length > 1 && key < bufferConditions.length - 1
          "
        >
          <v-autocomplete
            v-model="bufferConditions[parseInt(key) + 1].integrationOfOperation"
            :items="['AND', 'OR']"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
            class="mr-3 survey_conditional_select"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </template>
    <v-row class="mt-0">
      <v-col cols="12" md="12" class="text-right">
        <b-button
          v-if="bufferConditions.length > 0"
          @click="add"
          size="sm"
          class="btn-small mr-1 no-radius survey_conditional_andor_btn"
          variant="outline-secondary"
        >
          Save
        </b-button>
      </v-col>
    </v-row>

    <div
      class="border px-1 py-1 mt-3"
      style="color: blueviolet"
      :key="`conditions_values_${key}`"
      v-for="(cond, key) in editedQuestionCond"
    >
      <div class="d-flex justify-content-between align-center">
        {{ formatCondStr(cond, key) }}
        <input
          type="button"
          class="button-xs mr-1"
          value="X"
          @click="remove(cond)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
import { QUESTION_TYPES, OP_STRING, OP_BOOL, OP_NUMBER } from "../models";
import { ellipsis, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "conditionalBuilderV2",
  props: ["question", "components"],
  data() {
    return {
      QUESTION_TYPES,

      operatorOpt: {
        [QUESTION_TYPES.DATE]: OP_NUMBER,

        [QUESTION_TYPES.BOOL]: OP_BOOL,

        [QUESTION_TYPES.STRING]: OP_STRING,

        [QUESTION_TYPES.LONGTEXT]: OP_STRING,

        [QUESTION_TYPES.RADIO]: OP_STRING,

        [QUESTION_TYPES.CHECKBOX]: OP_STRING,

        [QUESTION_TYPES.SELECT]: OP_STRING,

        [QUESTION_TYPES.MONEY]: OP_NUMBER,

        [QUESTION_TYPES.DECIMAL]: OP_NUMBER,

        [QUESTION_TYPES.NUMBER]: OP_NUMBER,

        [QUESTION_TYPES.ACTION]: OP_STRING,
      },

      bufferConditions: [],
      templateCondition: {
        component: null,
        question: null,
        integrationOfOperation: "AND",
        labelOfselectedValue: null,
        op: null,
        val: null,
        isInvalid: false,
        error_message: "",
      },
    };
  },

  computed: {
    editedQuestionCond() {
      if (this.question?.conditionals) {
        return this.question?.conditionals;
      }
      return [];
    },
  },

  methods: {
    ellipsis,

    xQuestions(conditionItem) {
      if (conditionItem) {
        let foundComponent = this.components.find(
          (el) => el.uuid === conditionItem.component
        );
        if (foundComponent) {
          return foundComponent.survey_questions;
        }
      }
      return [];
    },
    xType(conditionItem) {
      let foundQuestionItem = this.currentQuestionItem(conditionItem);
      if (foundQuestionItem) {
        return foundQuestionItem.survey_question_type.type;
      }
      return null;
    },
    currentQuestionItem(conditionItem) {
      let foundQuestionItem = this.xQuestions(conditionItem).find(
        (el) => el.uuid === conditionItem.question
      );
      return foundQuestionItem ? foundQuestionItem : null;
    },
    selectedOperatorOpt(conditionItem) {
      let selectedQuestion = this.currentQuestionItem(conditionItem);
      let questionXType = this.xType(conditionItem);
      if (
        selectedQuestion &&
        questionXType &&
        this.operatorOpt[questionXType]
      ) {
        let array = [];
        for (const [key, value] of Object.entries(
          this.operatorOpt[questionXType]
        )) {
          let push_item = {
            label: key,
            value: value,
          };
          array.push(push_item);
        }
        return array;
      }
      return null;
    },
    isAvailableAddConditions(conditionItem) {
      let value = false;
      if (conditionItem.component && conditionItem.question) {
        if (
          ![
            QUESTION_TYPES.PHOTO,
            QUESTION_TYPES.STRING,
            QUESTION_TYPES.LONGTEXT,
          ].includes(this.xType(conditionItem))
        ) {
          value = true;
        }
      }
      return value;
    },
    selectedOperatorValueList(conditionItem) {
      let selectedQuestion = this.currentQuestionItem(conditionItem);
      let questionXType = this.xType(conditionItem);
      let valueList = [];
      if ([QUESTION_TYPES.ACTION].includes(questionXType)) {
        for (const [optValue, optItem] of Object.entries(
          selectedQuestion.options.values
        )) {
          let pushItem = {
            label: optItem.label,
            value: optValue,
          };
          valueList.push(pushItem);
        }
        return valueList;
      } else if (
        [
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.RADIO,
        ].includes(questionXType) &&
        selectedQuestion.options
      ) {
        for (const [optValue, optItem] of Object.entries(
          selectedQuestion.options.values
        )) {
          let pushItem = {
            label: optItem,
            value: optValue,
          };
          valueList.push(pushItem);
        }
      }
      return valueList;
    },
    addCheckValidation() {
      let isValidation = true;
      for (const condItem of this.bufferConditions) {
        if (!this.checkValidationCondItem(condItem)) {
          isValidation = false;
          break;
        }
      }
      return isValidation;
    },
    addBufferItem() {
      let isValidation = this.addCheckValidation();
      if (!isValidation) {
        logError("valores nulos no aceptados");
        return;
      }
      this.bufferConditions.push(
        JSON.parse(JSON.stringify(this.templateCondition))
      );
    },
    checkValidationCondItem(conditionItem) {
      const {
        component,
        question,
        op,
        integrationOfOperation,
        val,
      } = conditionItem;
      let isAddFailed = true;
      if (!component || !question || !op || !integrationOfOperation || !val) {
        isAddFailed = false;
      }
      return isAddFailed;
    },
    isCheckConditionalsMinMax(selectedQuestion, toAddCondItem) {
      let isValid = true;
      let error_message = "";
      let currentValue = parseFloat(toAddCondItem.val);
      if (isNaN(currentValue)) {
        isValid = false;
      } else {
        if (selectedQuestion?.options) {
          if (
            Object.keys(selectedQuestion.options).includes("min") &&
            !isNaN(selectedQuestion.options.min)
          ) {
            if (selectedQuestion.options.min > currentValue) {
              error_message = `el valor introducido es inferior al mínimo(${selectedQuestion.options.min})`;
              isValid = false;
            }
          }
          if (
            Object.keys(selectedQuestion.options).includes("max") &&
            !isNaN(selectedQuestion.options.max)
          ) {
            if (selectedQuestion.options.max < currentValue) {
              error_message = `el valor introducido es superior al máximo(${selectedQuestion.options.max})`;
              isValid = false;
            }
          }
        }
      }
      return { isValid, error_message };
    },
    add() {
      let isValidation = this.addCheckValidation();
      if (!isValidation) {
        logError("valores nulos no aceptados");
        return;
      }
      if (this.bufferConditions.length > 0) {
        let isValidateCriteria = true;
        for (let i = 0; i < this.bufferConditions.length; i++) {
          let toAddCondItem = this.bufferConditions[i];
          let questionXType = this.xType(toAddCondItem);
          let selectedQuestion = this.currentQuestionItem(toAddCondItem);
          if (
            [
              QUESTION_TYPES.NUMBER,
              QUESTION_TYPES.DECIMAL,
              QUESTION_TYPES.MONEY,
            ].includes(questionXType)
          ) {
            let { isValid, error_message } = this.isCheckConditionalsMinMax(
              selectedQuestion,
              toAddCondItem
            );
            if (!isValid) {
              this.bufferConditions[i].isInvalid = true;
              this.bufferConditions[i].error_message = error_message;
              isValidateCriteria = false;
            } else {
              this.bufferConditions[i].isInvalid = false;
              this.bufferConditions[i].error_message = "";
            }
          }
        }
        if (!isValidateCriteria) {
          logError("Input Error !");
          return;
        }
        for (const toAddCondItem of this.bufferConditions) {
          let labelOfselectedValue = null;
          let questionXType = this.xType(toAddCondItem);
          let selectedQuestion = this.currentQuestionItem(toAddCondItem);
          if (
            [
              QUESTION_TYPES.SELECT,
              QUESTION_TYPES.BOOL,
              QUESTION_TYPES.CHECKBOX,
              QUESTION_TYPES.RADIO,
              QUESTION_TYPES.ACTION,
            ].includes(questionXType)
          ) {
            if (questionXType === QUESTION_TYPES.ACTION) {
              labelOfselectedValue =
                selectedQuestion.options.values[toAddCondItem.val]?.label;
            } else {
              labelOfselectedValue =
                selectedQuestion.options.values[toAddCondItem.val];
            }
          }
          const toAdd = {
            ...toAddCondItem,
            labelOfselectedValue,
          };
          if (!this.question.conditionals) {
            this.question.conditionals = [];
          }
          if (
            this.question.conditionals &&
            !this.question.conditionals.some((el) => _.isEqual(el, toAdd))
          ) {
            //checking duplicates
            this.question.conditionals.push(toAdd);
          }
        }
      }
    },
    getOp(condOp) {
      return condOp;
    },
    getComponentQuestionItem(components, condItem) {
      let thisComponent = null;
      let thisQuestion = null;
      let filteredComponent = components.filter(
        (el) => el.uuid === condItem.component
      );
      if (filteredComponent.length > 0) {
        thisComponent = filteredComponent[0];
        if (thisComponent.survey_questions) {
          let filteredQuestion = thisComponent.survey_questions.filter(
            (el) => el.uuid === condItem.question
          );
          if (filteredQuestion.length > 0) {
            thisQuestion = filteredQuestion[0];
          }
        }
      }
      return { thisComponent, thisQuestion };
    },
    formatCondStr(cond, key) {
      let { thisComponent, thisQuestion } = this.getComponentQuestionItem(
        this.components,
        cond
      );
      let selected_op = this.getOp(cond.op);
      let componentName = cond.component;
      let questionName = cond.question;
      if (thisComponent) {
        componentName = thisComponent.name;
      }
      if (thisQuestion) {
        questionName = thisQuestion.name;
      }
      let label = cond?.labelOfselectedValue;
      if (!label) {
        label = cond.val;
      }
      return `${key > 0 ? cond.integrationOfOperation + " - " : ""}[${ellipsis(
        componentName,
        18
      )}] - [${ellipsis(questionName, 18)}] - ${selected_op}: ${label}`;
    },
    remove(cond) {
      this.question.conditionals = this.question.conditionals.filter(
        (el) => !_.isEqual(el, cond)
      );
    },

    onChangeComponent(conditionItem, key) {
      this.bufferConditions[key].question = null;
      this.bufferConditions[key].val = null;
      this.bufferConditions[key].op = null;
      this.bufferConditions[key].labelOfselectedValue = null;
    },
    onChangeComponentQuestion(conditionItem, key) {
      this.bufferConditions[key].val = null;
      this.bufferConditions[key].op = null;
      this.bufferConditions[key].labelOfselectedValue = null;
    },

    initBufferConditions() {
      this.bufferConditions = [];
      this.bufferConditions.push(
        JSON.parse(JSON.stringify(this.templateCondition))
      );
    },
    init() {
      if (this.question && !this.question.conditionals) {
        this.question.conditionals = [];
      }
      if (this.question && this.question.conditionals) {
        let cond_type = typeof this.question.conditionals;
        if (cond_type === "string") {
          this.question.conditionals = JSON.parse(this.question.conditionals);
        }
      }

      this.initBufferConditions();
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
.survey_conditional_select .v-input__slot {
  min-height: 30px !important;
}
.survey_conditional_andor_btn {
  min-height: 30px !important;
}
</style>

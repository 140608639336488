<template>
  <fieldset class="m-0 p-2">
    <legend><b-badge class="legend-title">aparece si</b-badge></legend>

    <div class="d-flex flex-column mb-3">
      <v-autocomplete
        v-model="selectedComponentUUId"
        label="componente"
        :items="components"
        item-text="name"
        item-value="uuid"
        filled
        dense
        hide-details="auto"
        clearable
        @change="onChangeComponent"
      ></v-autocomplete>
    </div>

    <div class="d-flex flex-column mb-3">
      <v-autocomplete
        v-model="selectedQuestionUUId"
        label="pregunta"
        :items="xQuestions"
        item-text="name"
        item-value="uuid"
        filled
        dense
        hide-details="auto"
        clearable
      ></v-autocomplete>
    </div>

    <div class="d-flex flex-column" v-if="isAvailableAddConditions">
      <template v-for="(conditionItem, key) in bufferConditions">
        <div
          class="d-flex justify-content-between align-center mb-3"
          :key="`cond_item_${key}`"
        >
          <v-autocomplete
            v-if="key !== 0"
            v-model="conditionItem.integrationOfOperation"
            :items="['AND', 'OR']"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
            class="mr-3 survey_conditional_select"
          ></v-autocomplete>
          <div style="max-width: 150px;">
            <v-autocomplete
              v-model="conditionItem.selectedOperator"
              :items="selectedOperatorOpt"
              item-text="label"
              item-value="value"
              filled
              dense
              hide-details="auto"
              clearable
              class="mr-3 survey_conditional_select"
            ></v-autocomplete>
          </div>
          <div
            class="d-flex justify-content-between align-center"
            v-if="
              [
                QUESTION_TYPES.SELECT,
                QUESTION_TYPES.BOOL,
                QUESTION_TYPES.CHECKBOX,
                QUESTION_TYPES.ACTION,
                QUESTION_TYPES.RADIO,
              ].includes(selectedQuestion.survey_question_type.type)
            "
          >
            <v-autocomplete
              v-model="conditionItem.selectedOperatorValue"
              :items="selectedOperatorValueList"
              item-text="label"
              item-value="value"
              filled
              dense
              hide-details="auto"
              clearable
              class="mr-3 survey_conditional_select"
            ></v-autocomplete>
          </div>
          <div class="d-flex justify-content-between align-center" v-else>
            <template
              v-if="
                [QUESTION_TYPES.DATE].includes(
                  selectedQuestion.survey_question_type.type
                )
              "
            >
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="es"
                v-model="conditionItem.selectedOperatorValue"
              >
              </b-form-datepicker>
            </template>
            <template v-else>
              <v-text-field
                class="mr-3"
                v-model="conditionItem.selectedOperatorValue"
                dense
                hide-details="auto"
              ></v-text-field>
            </template>
          </div>
        </div>
        <div
          class="d-flex align-center justify-content-end mb-3"
          :key="`cond_item_new_row_${key}`"
        >
          <b-button
            @click="addBufferItem"
            size="sm"
            class="btn-small mr-1 no-radius survey_conditional_andor_btn"
            variant="outline-secondary"
          >
            AND/OR
          </b-button>
          <b-button
            @click="add(conditionItem, key)"
            size="sm"
            class="btn-small mr-1 no-radius survey_conditional_andor_btn"
            variant="outline-secondary"
          >
            ADD
          </b-button>
        </div>
      </template>
    </div>

    <div
      class="border px-1 py-1"
      style="color: blueviolet"
      :key="`conditions_values_${key}`"
      v-for="(cond, key) in this.question.conditionals"
    >
      <div class="d-flex justify-content-between align-center">
        {{ formatCondStr(cond, key) }}
        <input
          type="button"
          class="button-xs mr-1"
          value="X"
          @click="remove(cond)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
import { QUESTION_TYPES } from "../models";
import { ellipsis, logError } from "@/utils";
import _ from "lodash";

const OP_STRING = {
  igual: "eq",
  diferente: "ne",
};

const OP_BOOL = {
  igual: "eq",
};

const OP_NUMBER = {
  "=": "eq",
  "<": "lt",
  "<=": "le",
  ">": "gt",
  ">=": "ge",
};

export default {
  props: ["question", "components"],
  data() {
    return {
      selectedComponentUUId: null,
      selectedQuestionUUId: null,
      selectedOperator: null,
      selectedOperatorValue: null,
      selectedValue: null,

      bufferConditions: null,
      templateCondition: {
        selectedOperator: null,
        selectedOperatorValue: null,
        integrationOfOperation: "AND",
      },

      QUESTION_TYPES,

      operatorOpt: {
        [QUESTION_TYPES.DATE]: OP_NUMBER,

        [QUESTION_TYPES.BOOL]: OP_BOOL,

        [QUESTION_TYPES.STRING]: OP_STRING,

        [QUESTION_TYPES.LONGTEXT]: OP_STRING,

        [QUESTION_TYPES.RADIO]: OP_STRING,

        [QUESTION_TYPES.CHECKBOX]: OP_STRING,

        [QUESTION_TYPES.SELECT]: OP_STRING,

        [QUESTION_TYPES.MONEY]: OP_NUMBER,

        [QUESTION_TYPES.DECIMAL]: OP_NUMBER,

        [QUESTION_TYPES.NUMBER]: OP_NUMBER,

        [QUESTION_TYPES.ACTION]: OP_STRING,
      },
    };
  },

  computed: {
    selectedComponent() {
      if (this.selectedComponentUUId) {
        let filtered = this.components.filter(
          (el) => el.uuid === this.selectedComponentUUId
        );
        if (filtered.length > 0) {
          return filtered[0];
        }
      }
      return null;
    },

    xQuestions() {
      if (this.selectedComponent) {
        return this.selectedComponent.survey_questions.filter(
          // (q) => q.isCompleted() && q.uuid !== this.question.uuid
          // (q) => q.uuid === this.question.uuid
          (q) => q.uuid !== this.question.uuid
        );
      }
      return null;
    },

    selectedQuestion() {
      if (this.selectedComponentUUId && this.selectedQuestionUUId) {
        let filtered = this.xQuestions.filter(
          (el) => el.uuid === this.selectedQuestionUUId
        );
        if (filtered.length > 0) {
          return filtered[0];
        }
      }
      return null;
    },

    selectedOperatorOpt() {
      if (this.selectedQuestion && this.xType && this.operatorOpt[this.xType]) {
        let array = [];
        for (const [key, value] of Object.entries(
          this.operatorOpt[this.xType]
        )) {
          let push_item = {
            label: key,
            value: value,
          };
          array.push(push_item);
        }
        return array;
      }
      return null;
    },

    selectedOperatorValueList() {
      let valueList = [];
      if (
        [QUESTION_TYPES.ACTION].includes(
          this.selectedQuestion.survey_question_type.type
        )
      ) {
        for (const [optValue, optItem] of Object.entries(
          this.selectedQuestion.options.values
        )) {
          let pushItem = {
            label: optItem.label,
            value: optValue,
          };
          valueList.push(pushItem);
        }
        return valueList;
      } else if (
        [
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.RADIO,
        ].includes(this.selectedQuestion.survey_question_type.type) &&
        this.selectedQuestion.options
      ) {
        for (const [optValue, optItem] of Object.entries(
          this.selectedQuestion.options.values
        )) {
          let pushItem = {
            label: optItem,
            value: optValue,
          };
          valueList.push(pushItem);
        }
      }
      return valueList;
    },

    xType() {
      return this.selectedQuestion
        ? this.selectedQuestion.survey_question_type.type
        : null;
    },

    isAvailableAddConditions() {
      let value = false;
      if (this.selectedComponentUUId && this.selectedQuestionUUId) {
        if (
          ![
            QUESTION_TYPES.PHOTO,
            QUESTION_TYPES.STRING,
            QUESTION_TYPES.LONGTEXT,
          ].includes(this.xType)
        ) {
          value = true;
        }
      }
      return value;
    },
  },

  methods: {
    onChangeComponent() {
      this.selectedQuestionUUId = null;
    },

    getOp(condOp) {
      return condOp;
    },

    getComponentQuestionItem(components, condItem) {
      let thisComponent = null;
      let thisQuestion = null;
      let filteredComponent = components.filter(
        (el) => el.uuid === condItem.component
      );
      if (filteredComponent.length > 0) {
        thisComponent = filteredComponent[0];
        if (thisComponent.survey_questions) {
          let filteredQuestion = thisComponent.survey_questions.filter(
            (el) => el.uuid === condItem.question
          );
          if (filteredQuestion.length > 0) {
            thisQuestion = filteredQuestion[0];
          }
        }
      }
      return { thisComponent, thisQuestion };
    },

    formatCondStr(cond, key) {
      let { thisComponent, thisQuestion } = this.getComponentQuestionItem(
        this.components,
        cond
      );
      let selected_op = this.getOp(cond.op);
      let componentName = cond.component;
      let questionName = cond.question;
      if (thisComponent) {
        componentName = thisComponent.name;
      }
      if (thisQuestion) {
        questionName = thisQuestion.name;
      }
      let label = cond?.labelOfselectedValue;
      if (!label) {
        label = cond.val;
      }
      return `${key > 0 ? cond.integrationOfOperation + " - " : ""}[${ellipsis(
        componentName,
        18
      )}] - [${ellipsis(questionName, 18)}] - ${selected_op}: ${label}`;
    },

    ellipsis,

    addBufferItem() {
      this.bufferConditions.push(
        JSON.parse(JSON.stringify(this.templateCondition))
      );
    },
    add(conditionItem, key) {
      const {
        selectedOperator,
        selectedOperatorValue,
        integrationOfOperation,
      } = conditionItem;
      let isAddFailed = true;
      if (key === 0) {
        if (!selectedOperator || !selectedOperatorValue) {
          isAddFailed = false;
        }
      } else {
        if (
          !selectedOperator ||
          !selectedOperatorValue ||
          !integrationOfOperation
        ) {
          isAddFailed = false;
        }
      }
      if (!isAddFailed) {
        logError("valores nulos no aceptados");
        return;
      }
      let labelOfselectedValue = null;
      if (
        [
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.ACTION,
        ].includes(this.selectedQuestion.survey_question_type.type)
      ) {
        if (
          this.selectedQuestion.survey_question_type.type ===
          QUESTION_TYPES.ACTION
        ) {
          labelOfselectedValue = this.selectedQuestion.options.values[
            selectedOperatorValue
          ]?.label;
        } else {
          labelOfselectedValue = this.selectedQuestion.options.values[
            selectedOperatorValue
          ];
        }
      }
      const toAdd = {
        component: this.selectedComponent.uuid,
        question: this.selectedQuestion.uuid,
        op: selectedOperator,
        val: selectedOperatorValue,
        integrationOfOperation: integrationOfOperation,
        labelOfselectedValue,
      };
      if (
        this.question.conditionals &&
        !this.question.conditionals.some((el) => _.isEqual(el, toAdd))
      ) {
        //checking duplicates
        this.question.conditionals.push(toAdd);
      }
    },
    remove(cond) {
      this.question.conditionals = this.question.conditionals.filter(
        (el) => !_.isEqual(el, cond)
      );
    },
    initBufferConditions() {
      this.bufferConditions = [];
      this.bufferConditions.push(
        JSON.parse(JSON.stringify(this.templateCondition))
      );
    },
    init() {
      if (this.question && !this.question.conditionals) {
        this.question.conditionals = [];
      }
      if (this.question && this.question.conditionals) {
        let cond_type = typeof this.question.conditionals;
        if (cond_type === "string") {
          this.question.conditionals = JSON.parse(this.question.conditionals);
        }
      }
      this.initBufferConditions();
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
.survey_conditional_select .v-input__slot {
  min-height: 30px !important;
}
.survey_conditional_andor_btn {
  min-height: 30px !important;
}
</style>

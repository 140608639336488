<template>
  <fieldset class="m-0 mb-3 p-2">
    <legend><b-badge class="legend-title mb-3">Calculation</b-badge></legend>

    <template v-for="(calculationItem, key) in question.calculations">
      <v-row :key="`calc_comp_op_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="calculationItem.operation"
            label="operation"
            :items="xOperations"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="calculationItem.component"
            label="componente"
            :items="components"
            item-text="name"
            item-value="uuid"
            filled
            dense
            hide-details="auto"
            @change="onChangeComponent(calculationItem, key)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_que_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="calculationItem.question"
            label="pregunta"
            :items="xQuestions(calculationItem)"
            item-text="name"
            item-value="uuid"
            filled
            dense
            hide-details="auto"
            @change="onChangeComponentQuestion(calculationItem, key)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_proormat_${key}`">
        <v-col cols="12" md="12" v-if="!isLoadingProOrMat">
          <v-autocomplete
            v-model="calculationItem.options"
            label="Pro / Mat / Otros"
            :items="availableOptions[key]"
            item-text="name"
            item-value="id"
            filled
            dense
            hide-details="auto"
            multiple
            @change="onChangeProOrMatOptions(calculationItem, key)"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggleSelectAllOptions(calculationItem, key)"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ iconSelectAllCalcOptions(calculationItem, key) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_values_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="calculationItem.values"
            label="Values"
            :items="xAvailableValues(calculationItem, key)"
            filled
            dense
            hide-details="auto"
            multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_multipler_${key}`">
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="calculationItem.multipler"
            label="Multipler"
            :items="xMultiplers"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row :key="`calc_comp_format_${key}`">
        <v-col cols="12" md="12">
          <v-text-field
            type="number"
            v-model="calculationItem.format"
            label="Número"
            variant="outlined"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :key="`calc_add_integration_op_${key}`">
        <v-col cols="12" md="6">
          <b-button
            v-if="question.calculations.length > 1"
            @click="remove(calculationItem)"
            size="sm"
            class="btn-small mr-1 no-radius survey_conditional_andor_btn"
            variant="outline-secondary"
          >
            Remove
          </b-button>
          <b-button
            @click="addCalcItem"
            size="sm"
            class="btn-small mr-1 no-radius survey_conditional_andor_btn"
            variant="outline-secondary"
            v-if="question.calculations.length - 1 == key"
          >
            + Add
          </b-button>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="
            question.calculations.length > 1 &&
              key < question.calculations.length - 1
          "
        >
          <v-autocomplete
            v-model="
              question.calculations[parseInt(key) + 1].integrationOfOperation
            "
            @change="changedIntegrationOfOperation"
            :items="xIntegrationOps"
            item-text="label"
            item-value="value"
            filled
            dense
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        question?.calculations &&
          Array.isArray(question?.calculations) &&
          question?.calculations.length > 1
      "
    >
      <v-row>
        <v-col id="calc-parenethesys" cols="12" md="12">
          <v-text-field
            clearable
            type="text"
            v-model="question.calcParenthesys"
            label="Parenthesys For Calculation"
            variant="filled"
            @input="checkValidationForParenethesys"
            ref="calcParenthesys"
            readonly
          ></v-text-field>

          <b-alert
            v-if="parenthesysError"
            variant="light"
            style="color:red; padding:0; margin: 0"
            show
            >Parenthesys Error</b-alert
          >

          <v-col cols="12" md="12" justify="center">
            <v-btn
              variant="outlined"
              size="x-small"
              v-if="
                (lastOperator === 2 || lastOperator === 3) &&
                  question?.calculations[currentCompNumber - 1]
                    ?.integrationOfOperation === 'MULTIPLY'
              "
              @mousedown="appendOperatorForParenthesys('*')"
              >*</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="
                (lastOperator === 2 || lastOperator === 3) &&
                  question?.calculations[currentCompNumber - 1]
                    ?.integrationOfOperation === 'DIVIDE'
              "
              @mousedown="appendOperatorForParenthesys('/')"
              >/</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="
                (lastOperator === 2 || lastOperator === 3) &&
                  question?.calculations[currentCompNumber - 1]
                    ?.integrationOfOperation === 'SUM'
              "
              @mousedown="appendOperatorForParenthesys('+')"
              >+</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="
                (lastOperator === 2 || lastOperator === 3) &&
                  question?.calculations[currentCompNumber - 1]
                    ?.integrationOfOperation === 'SUBTRACT'
              "
              @mousedown="appendOperatorForParenthesys('-')"
              >-</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="
                (lastOperator === 0 || lastOperator === 1) &&
                  currentCompNumber <= question.calculations.length
              "
              @mousedown="appendOperatorForParenthesys('(')"
              >(</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="(lastOperator === 2 || lastOperator === 3) && countBracket"
              @mousedown="appendOperatorForParenthesys(')')"
              >)</v-btn
            >
            <v-btn
              variant="outlined"
              v-if="
                (lastOperator === 0 || lastOperator === 1) &&
                  currentCompNumber <= question.calculations.length
              "
              @mousedown="appendOperatorForParenthesys('0')"
              >Comp {{ currentCompNumber }}</v-btn
            >
          </v-col>
        </v-col>
      </v-row>
    </template>
  </fieldset>
</template>

<script>
import {
  QUESTION_TYPES,
  OP_STRING,
  OP_BOOL,
  OP_NUMBER,
  CALC_OPERATION,
  CALC_MULTIPLER,
  CALC_INTEGRATION_OP
} from "../models";
import { ellipsis, logWarning, logError } from "@/utils";
import _ from "lodash";
import ApiService from "@/services/apiService";

export default {
  name: "calculationBuilder",
  props: [
    "question",
    "components",
    "checkValidationCondItem",
    "checkForParenthesys"
  ],
  data() {
    return {
      lastOperator: 0,
      currentCompNumber: 1,
      countBracket: 0,
      parenthesysError: false,

      QUESTION_TYPES,
      selectAllValue: -1,

      bufferCalculations: [],

      templateCalculation: {
        format: null, // number with 3 decimals
        // CALC_MULTIPLER - MULTIPLY or DIVIDE - symbols : X or /
        multipler: null,
        // CALC_OPERATION - Operation - COUNT, SUM, MULTIPLY, SUBTRACT, DIVIDE, AVERAGE
        operation: null,
        component: null, // component-uuid
        question: null, // question-uuid
        options: [], // products or materials or componentOptionKeys
        values: null, // if select,checkbox,bool, the values
        // CALC_INTEGRATION_OP - Operation - SUM, SUBTRACT, MULTIPLY, DIVIDE
        integrationOfOperation: "SUM"
        // isValid: false,
        // error_message: "",
      },
      availableProducts: [],
      availableMaterials: [],
      availableCompOptions: [],

      availableOptions: [],
      isLoadingProOrMat: true
    };
  },
  computed: {
    editedQuestionCalculations() {
      if (this.question?.calculations) {
        return this.question?.calculations;
      }
      return [];
    },
    xOperations() {
      let operations = [];
      for (const [op_key, op_val] of Object.entries(CALC_OPERATION)) {
        let pushItem = {
          label: op_val,
          value: op_key
        };
        operations.push(pushItem);
      }
      return operations;
    },
    xMultiplers() {
      let multiplers = [];
      for (const [op_key, op_val] of Object.entries(CALC_MULTIPLER)) {
        let pushItem = {
          label: op_val,
          value: op_key
        };
        multiplers.push(pushItem);
      }
      return multiplers;
    },
    xIntegrationOps() {
      let integrations = [];
      for (const [op_key, op_val] of Object.entries(CALC_INTEGRATION_OP)) {
        let pushItem = {
          label: op_val,
          value: op_key
        };
        integrations.push(pushItem);
      }
      return integrations;
    }
  },

  methods: {
    ellipsis,
    onChangeProOrMatOptions(calculationItem, key) {
      let isSelectedAll = false;
      if (calculationItem.options.includes(this.selectAllValue)) {
        isSelectedAll = true;
      }
      if (isSelectedAll) {
        if (
          calculationItem.options.length !==
          this.availableOptions[key].length + 1
        ) {
          console.log("REMOVE -1");
          this.question.calculations[key].options = this.question.calculations[
            key
          ].options.filter(el => el !== this.selectAllValue);
        }
      } else {
        if (
          calculationItem.options.length === this.availableOptions[key].length
        ) {
          console.log("ADD -1");
          this.clickedSelectAllXProOrMatOptions(calculationItem, key);
        }
      }
    },
    likesAllCalcOptions(calculationItem, key) {
      return calculationItem.options.includes(this.selectAllValue);
    },
    iconSelectAllCalcOptions(calculationItem, key) {
      if (this.likesAllCalcOptions(calculationItem, key)) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    clickedSelectAllXProOrMatOptions(calculationItem, key) {
      this.question.calculations[key].options = this.availableOptions[key].map(
        el => el.id
      );
      this.question.calculations[key].options.push(this.selectAllValue);
      this.question.calculations[key].options = _.uniq(
        this.question.calculations[key].options
      );
    },
    toggleSelectAllOptions(calculationItem, key) {
      if (calculationItem?.options) {
        if (calculationItem.options.includes(this.selectAllValue)) {
          this.question.calculations[key].options = [];
        } else {
          this.clickedSelectAllXProOrMatOptions(calculationItem, key);
        }
      }
    },
    clearForParenthesysCalc() {
      this.currentCompNumber = 1;
      this.countBracket = 0;
      this.lastOperator = 0;
      this.question.calcParenthesys = null;
    },
    appendOperatorForParenthesys(operator) {
      if (
        operator === "*" ||
        operator === "-" ||
        operator === "/" ||
        operator === "+"
      ) {
        this.lastOperator = 0; // 2,3
      } else if (operator === "(") {
        this.lastOperator = 1; // 0,1
        this.countBracket++;
      } else if (operator === ")") {
        this.lastOperator = 2; // 3,2
        this.countBracket--;
      } else {
        this.lastOperator = 3; // 0,1
      }
      if (this.question.calcParenthesys === null)
        this.question.calcParenthesys = "";
      if (operator === "0") {
        this.question.calcParenthesys += this.currentCompNumber.toString();
        this.currentCompNumber++;
      } else {
        this.question.calcParenthesys += operator;
      }

      this.question.calcParenthesys += " ";
      this.checkValidationForParenethesys();
    },

    changedIntegrationOfOperation() {
      this.checkValidationForParenethesys();
    },
    checkValidationForParenethesys() {
      if (this.question.calcParenthesys === null)
        this.clearForParenthesysCalc();
      let validation = this.checkForParenthesys(
        this.question.calcParenthesys,
        this.question.calculations,
        false
      );
      
      if (validation !== 5) {
        if (this.question.calcParenthesys !== null)
          this.parenthesysError = true;
        else this.parenthesysError = false;
        this.$refs.calcParenthesys.focus();
      } else {
        this.parenthesysError = false;
      }
    },

    xQuestions(item) {
      let questions = [];
      if (item) {
        let foundComponent = this.components.find(
          el => el.uuid === item.component
        );
        if (foundComponent) {
          if (item.operation === "COUNT") {
            questions = foundComponent.survey_questions.filter(
              el =>
                el &&
                [
                  QUESTION_TYPES.BOOL,
                  QUESTION_TYPES.RADIO,
                  QUESTION_TYPES.CHECKBOX,
                  QUESTION_TYPES.SELECT,
                  QUESTION_TYPES.ACTION
                ].includes(el?.survey_question_type?.type)
            );
          } else {
            questions = foundComponent.survey_questions.filter(
              el =>
                el &&
                [
                  QUESTION_TYPES.MONEY,
                  QUESTION_TYPES.DECIMAL,
                  QUESTION_TYPES.NUMBER
                ].includes(el?.survey_question_type?.type)
            );
          }
        }
      }
      return questions;
    },
    remove(calc) {
      this.clearForParenthesysCalc();
      if (this.question.calculations.length > 1) {
        this.question.calculations = this.question.calculations.filter(
          el => !_.isEqual(el, calc)
        );
      }
    },
    addCheckValidation() {
      let isValidation = true;
      for (const calcItem of this.question.calculations) {
        if (!this.checkValidationCondItem(calcItem)) {
          isValidation = false;
          break;
        }
      }
      return isValidation;
    },
    addCalcItem() {
      this.clearForParenthesysCalc();
      let isValidation = this.addCheckValidation();
      if (!isValidation) {
        logWarning("Error Calculation - valores nulos no aceptados !");
        return;
      }
      this.question.calculations.push(
        JSON.parse(JSON.stringify(this.templateCalculation))
      );
    },
    xAvailableValues(calcItem, key) {
      let values = [];
      let selectedComponent = this.components.find(
        el => el.uuid == calcItem.component
      );
      if (selectedComponent && calcItem.question) {
        const { survey_questions } = selectedComponent;
        if (survey_questions) {
          let selectedQuestion = survey_questions.find(
            el => el.uuid == calcItem.question
          );
          if (selectedQuestion && selectedQuestion?.options?.values) {
            values = Object.values(selectedQuestion?.options?.values);
          }
        }
      }
      return values;
    },
    xProMatOptions(calcItem, key) {
      let selectedComponent = this.components.find(
        el => el.uuid == calcItem.component
      );
      let proOrMatOptions = [];
      if (selectedComponent) {
        const { assignType } = selectedComponent;
        if (assignType === "PRODUCT") {
          proOrMatOptions = this.availableProducts[key];
        } else if (assignType === "MATERIAL") {
          proOrMatOptions = this.availableMaterials[key];
        } else if (assignType === "OTHERS") {
          proOrMatOptions = this.availableCompOptions[key];
          if (proOrMatOptions) {
            proOrMatOptions = proOrMatOptions.map(el => {
              let item = { ...el };
              item.id = item.optionValue;
              item.name = item.optionLabel;
              return item;
            });
          }
        } else {
          proOrMatOptions = [];
        }
      }
      this.availableOptions[key] = proOrMatOptions;
      return proOrMatOptions;
    },
    async onChangeComponent(calcItem, key) {
      this.question.calculations[key].question = null;
      this.question.calculations[key].values = null;
      this.question.calculations[key].options = [];
      let selectedComponent = this.components.find(
        el => el.uuid == calcItem.component
      );
      if (selectedComponent) {
        this.isLoadingProOrMat = true;
        const {
          id,
          surveyId,
          assignType,
          component_options
        } = selectedComponent;
        this.availableCompOptions[key] = [];
        if (component_options && component_options?.values) {
          this.availableCompOptions[key] = Object.values(
            component_options?.values
          );
        }

        let getCriteriaOfPuncDetails = {
          surveyId,
          componentId: id,
          componentAssignType: assignType
        };
        const { chainList, materials, products } = await ApiService.post(
          `surveys/getInitialCriteriaOfPuncDetails`,
          getCriteriaOfPuncDetails
        );
        this.availableMaterials[key] = materials;
        this.availableProducts[key] = products;
        this.availableOptions[key] = this.xProMatOptions(calcItem, key);
        this.isLoadingProOrMat = false;
      }
    },
    onChangeComponentQuestion(calcItem, key) {},
    initCalculations() {
      if (this.question.calculations.length === 0) {
        this.question.calculations.push(
          JSON.parse(JSON.stringify(this.templateCalculation))
        );
      }
    },
    async init() {
      this.isLoadingProOrMat = true;
      if (this.question && !this.question.calculations) {
        this.question.calculations = [];
      } else if (this.question && this.question.calculations) {
        let cond_type = typeof this.question.calculations;
        if (cond_type === "string") {
          this.question.calculations = JSON.parse(this.question.calculations);
        }
      }
      this.initCalculations();
      if (
        this.question?.calculations &&
        Array.isArray(this.question?.calculations) &&
        this.question?.calcParenthesys !== null
      ) {
        this.currentCompNumber = this.question.calculations.length + 1;
      }
      this.availableCompOptions = [];
      this.availableMaterials = [];
      this.availableProducts = [];
      this.availableOptions = [];
      if (this.question.calculations && this.question.calculations.length > 0) {
        let key = 0;
        for (const calcItem of this.question.calculations) {
          let selectedComponent = this.components.find(
            el => el.uuid == calcItem.component
          );
          if (selectedComponent) {
            const {
              id,
              surveyId,
              assignType,
              component_options
            } = selectedComponent;
            this.availableCompOptions[key] = [];
            if (component_options && component_options?.values) {
              this.availableCompOptions[key] = Object.values(
                component_options?.values
              );
            }
            let getCriteriaOfPuncDetails = {
              surveyId,
              componentId: id,
              componentAssignType: assignType
            };
            const { chainList, materials, products } = await ApiService.post(
              `surveys/getInitialCriteriaOfPuncDetails`,
              getCriteriaOfPuncDetails
            );
            this.availableMaterials[key] = materials;
            this.availableProducts[key] = products;
          }
          this.availableOptions[key] = this.xProMatOptions(calcItem, key);
          if (calcItem.options.includes(this.selectAllValue)) {
            this.clickedSelectAllXProOrMatOptions(calcItem, key);
          }
          key++;
        }
      }
      this.isLoadingProOrMat = false;
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style></style>

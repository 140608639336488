<template>
  <div>
    <modal
      name="modal-question-pos-objective"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ $t("objective") }} POS</span>
          </v-card-text>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  class="mb-2 ml-2"
                  color="blue darken-1"
                  @click="templatedownloadexcel"
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                  Plantilla Import
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mb-2 ml-2"
                  :loading="isSelectingImport"
                  @click="onUploadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  {{ importButtonText }}
                </v-btn>
                <input
                  ref="uploader_file"
                  class="d-none"
                  type="file"
                  accept=".xls,.xlsx"
                  @change="onFileChangedChainChannelPerBrand"
                />
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadexcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="columns"
                  :items="tableData"
                  :items-per-page="10"
                  :footer-props="{
                    'items-per-page-options': [10, 50, 100]
                  }"
                  :options.sync="options"
                  class="elevation-1"
                >
                  <template
                    v-for="(col, i) in filters"
                    v-slot:[`header.${i}`]="{ header }"
                  >
                    <div
                      style="display: inline-block; padding: 16px 0;"
                      v-bind:key="i"
                    >
                      <span>{{ header.text }}</span>
                    </div>
                    <div
                      style="float: right; margin-top: 8px"
                      v-bind:key="`A` + i"
                    >
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        transition="slide-y-transition"
                        left
                        fixed
                        style="position: absolute; right: 0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                            <v-icon
                              small
                              :color="
                                activeFilters[header.value] &&
                                activeFilters[header.value].length <
                                  filters[header.value].length
                                  ? 'red'
                                  : 'default'
                              "
                            >
                              mdi-filter-variant
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list flat dense class="pa-0">
                          <v-list-item-group
                            multiple
                            v-model="activeFilters[header.value]"
                            class="py-2"
                          >
                            <div class="search_input">
                              <v-text-field
                                hide-details
                                v-model="searchString[header.value]"
                                placeholder="Search ..."
                                class="pl-2 pr-2"
                                @input="searchItem(header.value)"
                              ></v-text-field>
                            </div>
                            <template
                              v-for="(item, j) in filters[header.value]"
                            >
                              <v-list-item
                                v-bind:key="`A${j}`"
                                :value="item"
                                :ripple="false"
                              >
                                <template v-slot:default="{ active, toggle }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      :true-value="item"
                                      @click="toggle"
                                      color="primary"
                                      :ripple="false"
                                      dense
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                          <v-row no-gutters>
                            <v-col cols="6">
                              <v-btn
                                text
                                block
                                @click="toggleAll(header.value)"
                                color="success"
                                >{{ $t("toggleall") }}</v-btn
                              >
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                text
                                block
                                @click="clearAll(header.value)"
                                color="warning"
                                >{{ $t("clearall") }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:item.objectiveValue="{ item }">
                    <v-text-field
                      type="number"
                      v-model="editedItem.objectiveValue"
                      :hide-details="true"
                      dense
                      v-if="item.posId === editedItem.posId"
                    ></v-text-field>
                    <span v-else>{{ item.objectiveValue }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div v-if="item.posId === editedItem.posId">
                      <v-icon color="red" class="mr-3" @click="close">
                        mdi-window-close
                      </v-icon>
                      <v-icon color="green" @click="save">
                        mdi-content-save
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon
                        color="green"
                        class="mr-3"
                        @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color="red"
                        class="mr-3"
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import { QUESTION_TYPES } from "../models";
import * as XLSX from "xlsx";

export default {
  name: "DialogQuestionPosObjectives",
  props: ["editedSurvey", "editedComponent", "editedQuestion"],
  components: {},
  data: function() {
    return {
      /****** IMPORT && EXPORT ******/
      isSelectingImport: false,
      selectedFile: null,
      defaultImportbuttonText: "Import",

      editedIndex: -1,
      editedItem: {
        posId: 0,
        objectiveValue: null
      },
      defaultItem: {
        posId: 0,
        objectiveValue: null
      },
      current_rows: null,
      tableData: [],
      tempdata: [],
      options: {},
      loading: false,
      filterCriteria: {},
      activeFilters: {},
      filters: {
        posId: [],
        pos_name: []
      },
      initFilterList: {
        posId: [],
        pos_name: []
      },
      searchString: {
        posId: "",
        pos_name: ""
      }
    };
  },
  watch: {
    async row(newVal, oldVal) {
      if (newVal) {
        this.init(newVal);
      }
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true
    }
  },
  computed: {
    surveyQuestionType() {
      let ret = null;
      if (this.editedQuestion && this.editedQuestion?.survey_question_type) {
        ret = this.editedQuestion?.survey_question_type.type;
      }
      return ret;
    },
    importButtonText() {
      return this.isSelectingImport
        ? "Uploading..."
        : this.defaultImportbuttonText;
    },
    columns() {
      let defaultColumns = [
        {
          text: `Id POS`,
          value: "posId",
          width: "15%",
          filter: value => {
            return this.activeFilters.posId
              ? this.activeFilters.posId.includes(value)
              : true;
          }
        },
        {
          text: `POS ${this.$t("Name")}`,
          value: "pos_name",
          filter: value => {
            return this.activeFilters.pos_name
              ? this.activeFilters.pos_name.includes(value)
              : true;
          }
        },
        {
          text: "Objectivo",
          value: "objectiveValue",
          sortable: false,
          width: "30%"
        }
      ];
      let actionCol = [
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ];
      let ret = [...defaultColumns, ...actionCol];
      return ret;
    }
  },
  methods: {
    async showModal(question) {
      await this.init(question);
      this.$modal.show("modal-question-pos-objective");
    },
    hideModal() {
      this.$modal.hide("modal-question-pos-objective");
    },
    /********** Table options **********/
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        this.filters[col] = this.initFilterList[col].filter(item =>
          item.toLowerCase().includes(search_str.toLowerCase())
        );
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.saveCurrentPosObjectives(this.editedItem);
        this.tableData = this.tableData
          .map((el, index) => {
            if (this.editedIndex == index) {
              let updated_item = {
                ...el,
                objectiveValue: this.editedItem.objectiveValue
              };
              return updated_item;
            }
            return el;
          })
          .filter(el => el !== null);
        this.close();
      }
    },
    async saveCurrentPosObjectives(editedRecord) {
      let form_data = {
        ...editedRecord
      };
      const response = await ApiService.post(
        `surveys/update_question_pos_objective/${editedRecord.id}`,
        form_data
      );
    },
    async deleteItem(item) {
      let deleteIndex = this.tableData.indexOf(item);
      if (window.confirm(this.$t("confirmdelete"))) {
        const response = await ApiService.delete(
          `surveys/delete_question_pos_objective/${item.id}`
        );
        if (response) {
          this.tableData = this.tableData
            .map((el, index) => {
              if (deleteIndex == index) {
                return null;
              }
              return el;
            })
            .filter(el => el !== null);
        }
      }
    },
    /****** IMPORT && EXPORT ******/
    async templatedownloadexcel() {
      let excel_data = [
        { posId: 1, objectiveValue: 100 },
        { posId: 2, objectiveValue: 250 }
      ];
      let headers = [
        { label: "ID POS", key: "posId" },
        { label: "Objective", key: "objectiveValue" }
      ];
      this.downloadExcelWithXLSX(
        headers,
        excel_data,
        "template_data",
        "pos_objectives"
      );
    },
    async downloadexcel() {
      let excel_data = this.tableData.map(el => {
        let pushItem = {
          ...el
        };
        return pushItem;
      });
      let headers = [
        { label: "ID POS", key: "posId" },
        { label: "POS Name", key: "pos_name" },
        { label: "Objective", key: "objectiveValue" }
      ];
      this.downloadExcelWithXLSX(
        headers,
        excel_data,
        "survey_question_pos_objectives",
        "pos_objectives"
      );
    },
    onUploadExcel() {
      window.addEventListener("focus", () => {}, { once: false });
      this.$refs.uploader_file.click();
    },
    async onFileChangedChainChannelPerBrand(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingImport = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader_file.value = "";
        try {
          const response = await ApiService.post(
            `surveys/uploadexcelForParsePosObjectives/${this.editedQuestion.id}`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          if (success && insertedRowCount > 0) {
            await this.init(this.editedQuestion);
          }
          this.isSelectingImport = false;
        } catch (error) {
          console.log(error);
          this.isSelectingImport = false;
        }
      }
    },

    async init(question) {
      let survey_question_pos_objectives = [];
      try {
        const response = await ApiService.get(
          `surveys/get_survey_question_pos_objectives/${question.id}`
        );
        if (response?.data) {
          survey_question_pos_objectives = response.data;
        }
      } catch (error) {
        console.log("error - ", error);
      }
      this.current_rows = survey_question_pos_objectives;
      if (this.current_rows) {
        this.current_rows = this.current_rows.map(item => {
          let push_item = { ...item };
          if (push_item?.po) push_item.pos_name = push_item.po.name;
          return push_item;
        });
      } else {
        this.current_rows = [];
      }
      this.tableData = this.current_rows;
      this.tempdata = this.current_rows;
      this.initFilters();
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style></style>

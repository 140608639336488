var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"m-0 mb-3 p-2"},[_c('legend',[_c('b-badge',{staticClass:"legend-title"},[_vm._v("aparece si")])],1),_vm._l((_vm.bufferConditions),function(conditionItem,key){return [_c('v-row',{key:`cond_comp_${key}`},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"componente","items":_vm.components,"item-text":"name","item-value":"uuid","filled":"","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.onChangeComponent(conditionItem, key)}},model:{value:(conditionItem.component),callback:function ($$v) {_vm.$set(conditionItem, "component", $$v)},expression:"conditionItem.component"}})],1)],1),_c('v-row',{key:`cond_comp_que_${key}`},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"pregunta","items":_vm.xQuestions(conditionItem),"item-text":"name","item-value":"uuid","filled":"","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.onChangeComponentQuestion(conditionItem, key)}},model:{value:(conditionItem.question),callback:function ($$v) {_vm.$set(conditionItem, "question", $$v)},expression:"conditionItem.question"}})],1)],1),(_vm.isAvailableAddConditions(conditionItem))?_c('v-row',{key:`cond_item_${key}`},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":_vm.selectedOperatorOpt(conditionItem),"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto"},model:{value:(conditionItem.op),callback:function ($$v) {_vm.$set(conditionItem, "op", $$v)},expression:"conditionItem.op"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
            [
              _vm.QUESTION_TYPES.SELECT,
              _vm.QUESTION_TYPES.BOOL,
              _vm.QUESTION_TYPES.CHECKBOX,
              _vm.QUESTION_TYPES.ACTION,
              _vm.QUESTION_TYPES.RADIO,
            ].includes(_vm.xType(conditionItem))
          )?_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":_vm.selectedOperatorValueList(conditionItem),"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto"},model:{value:(conditionItem.val),callback:function ($$v) {_vm.$set(conditionItem, "val", $$v)},expression:"conditionItem.val"}})],1):_c('div',{staticClass:"d-flex justify-content-between align-center"},[([_vm.QUESTION_TYPES.DATE].includes(_vm.xType(conditionItem)))?[_c('b-form-datepicker',{attrs:{"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"locale":"es"},model:{value:(conditionItem.val),callback:function ($$v) {_vm.$set(conditionItem, "val", $$v)},expression:"conditionItem.val"}})]:[_c('v-text-field',{class:`mr-3`,attrs:{"dense":"","hide-details":"auto","error-messages":conditionItem?.isInvalid ? conditionItem.error_message : ''},model:{value:(conditionItem.val),callback:function ($$v) {_vm.$set(conditionItem, "val", $$v)},expression:"conditionItem.val"}})]],2)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center justify-content-end mb-3"},[_c('b-button',{staticClass:"btn-small mr-1 no-radius survey_conditional_andor_btn",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.addBufferItem}},[_vm._v(" + AND/OR ")])],1)]),(
          _vm.bufferConditions.length > 1 && key < _vm.bufferConditions.length - 1
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"mr-3 survey_conditional_select",attrs:{"items":['AND', 'OR'],"item-text":"label","item-value":"value","filled":"","dense":"","hide-details":"auto"},model:{value:(_vm.bufferConditions[parseInt(key) + 1].integrationOfOperation),callback:function ($$v) {_vm.$set(_vm.bufferConditions[parseInt(key) + 1], "integrationOfOperation", $$v)},expression:"bufferConditions[parseInt(key) + 1].integrationOfOperation"}})],1):_vm._e()],1):_vm._e()]}),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[(_vm.bufferConditions.length > 0)?_c('b-button',{staticClass:"btn-small mr-1 no-radius survey_conditional_andor_btn",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.add}},[_vm._v(" Save ")]):_vm._e()],1)],1),_vm._l((_vm.editedQuestionCond),function(cond,key){return _c('div',{key:`conditions_values_${key}`,staticClass:"border px-1 py-1 mt-3",staticStyle:{"color":"blueviolet"}},[_c('div',{staticClass:"d-flex justify-content-between align-center"},[_vm._v(" "+_vm._s(_vm.formatCondStr(cond, key))+" "),_c('input',{staticClass:"button-xs mr-1",attrs:{"type":"button","value":"X"},on:{"click":function($event){return _vm.remove(cond)}}})])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }